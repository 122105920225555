<template>
	<div id="app">
		<Head @custom-event="ChangeAccount" />
		<router-view ref="routerView" />
	</div>
</template>
<script>
import Head from '@/components/Head.vue';
export default {
	components: {
		// eslint-disable-next-line vue/no-unused-components
		Head: Head,
	},
	methods: {
		ChangeAccount(account) {
			// 通过引用调用 router-view 组件的方法
			this.$refs.routerView.changeAccount(account);
		}
	}
};
(function () {
	function setRemUnit() {
		const docEl = document.documentElement;
		const width = docEl.clientWidth;

		// 设置设计稿宽度，这里假设设计稿宽度为375px
		const designWidth = 375;

		// 设置根元素字体大小为 (当前屏幕宽度 / 设计稿宽度) * 初始字体大小
		docEl.style.fontSize = (width / designWidth) * 12 + 'px';
	}

	// 初始化时设置一次
	setRemUnit();

	// 窗口大小改变时重新设置
	window.addEventListener('resize', setRemUnit);
})();
</script>

<style lang="scss">
html {
	font-size: 12px;
	background: #F2F2F2;
	overflow-x: hidden;
	/* 设置一个初始值，防止页面闪烁 */
}

body {
	margin: 0;
	padding: 0;
}

.el-message {
	z-index: 9999999 !important;
}

.el-loading-spinner i,
.el-loading-spinner .el-loading-text {
	color: #fff !important;
}

</style>
