const bigInt = require('big-integer');
//查询合约地址
export async function getContractAddress(){
    localStorage.removeItem("contract");
    var address = "";
    await this.$axios.post("/api/getContractAddress", {})
    .then((res) =>{
        address = res.data.data;
        //保存合约地址
        localStorage.setItem("contract", JSON.stringify(address));
        localStorage.setItem("TTTcontractAddress", address.tttContract);
        localStorage.setItem("MarketContractAddress", address.marketContract);
        localStorage.setItem("PledgeContractAddress", address.pledgeContract);
        localStorage.setItem("MainContractAddress", address.mainContract);
        localStorage.setItem("PledgeTokenAddress", address.pledgeToken);
    })
    .catch((error) =>{
        console.log(error);
    });
    return address;
}
//查询首页内容
export function getHome(){
    this.loadingHome = this.$loading({
        lock: false,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    this.$axios.post("/api/getHome", {
		token:{
            page:this.tokenParam.page,
            offset:this.tokenParam.offset,
            search:this.tokenParam.search,
        },
        order:{
            account:localStorage.getItem("userAccount"),
            type:this.orderTypeValue,
            status:this.orderStatusValue,
            page:this.orderPage,
            offset:this.orderOffset,
        }
	})
	.then((res) =>{
        var token = res.data.data.token;
        var order = res.data.data.order;

        this.tokens = token.data;
        this.tokenParam.count = token.count;

        this.orderList = order.data;
        this.orderCount = order.count;

        this.loadingHome.close()
	})
	.catch((error) =>{
        this.loadingHome.close()
		console.log(error);
	});
    
   
}
//查询所有铭文币
export function getTokenList(){
    this.$axios.post("/api/getAllTokens", {
		page:this.tokenParam.page,
        offset:this.tokenParam.offset,
        search:this.tokenParam.search,
	})
	.then((res) =>{
        this.tokens = res.data.data;
        this.tokenParam.count = res.data.count;
	})
	.catch((error) =>{
		console.log(error);
	});
}

//查询铭文币信息
export async function getTokenInfo(address,_this){
    _this.loading = _this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    var tokeninfo = {};
    await _this.$axios.post("/api/getTokenInfo", {
        address:address,
	})
	.then((res) =>{
        var result = res.data.data;
        // return结果
        tokeninfo = {
            totalSupply: bigInt(result.totalSupply) / 10 ** result.decimal,
            mintedSupply: bigInt(result.mintedSupply) / 10 ** result.decimal,
            maxMintPerTx: bigInt(result.maxMintPerTx) / 10 ** result.decimal,
            createTime: result.createTime,
            finishTime: result.finishTime,
            decimal: bigInt(result.decimal),
            transactions: bigInt(result.transactions),
            tokenImg: result.tokenImg,
            owner: result.owner,
            name: result.name,
            address: address,
            mintCount: result.mintCount,
            hash: result.hash,
            blockNumber: result.blockNumber,
        }
	}).catch((error) =>{
        console.log(error);
    });
    _this.loading.close();
    return tokeninfo;
}
//查询部署开放时间
export async function getOpenTime(){
    this.loading_open = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    try {
        await this.$axios.post("/api/getOpenTime", {})
        .then((res) =>{
            if(res.data.code == 200){
                if(res.data.djs>0){
                    //开启倒计时
                    let time = res.data.djs;
                    this.djs = formatTime(time,this);
                    this.djs_timer = setInterval(()=>{
                        time--;
                        this.djs = formatTime(time,this);
                        if(time<=0){
                            clearInterval(this.djs_timer);
                            this.show_mongban = false;
                        }
                    },1000)
                    this.show_mongban = true;
                }else{
                    this.show_mongban = false;
                }
            }
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
    this.loading_open.close();
}
function preventDefaultScroll(e) {
    e.preventDefault();
}
function formatTime(seconds,_this) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const h = _this.$t('api.t3');
    const m = _this.$t('api.t4');
    const s = _this.$t('api.t5');
    const formattedTime = `${hours}${h} ${minutes}${m} ${remainingSeconds}${s}`;

    return formattedTime;
}
  
//上传图片base64    
export async function uploadImg(base64, name){
    // this.loading = this.$loading({
    //     lock: true,
    //     text: this.$t('api.t1'),
    //     spinner: 'el-icon-loading',
    //     background: 'rgba(0, 0, 0, 0.7)'
    // });
    var img = null;
    await this.$axios.post("/api/uploadImg", {
        base64:base64,
        name:name,
    })
    .then((res) =>{
        img = res.data;
    }).catch((error) =>{
        console.log(error);
        img = {
            code: 1,
            msg: this.$t('api.t2')
        }
    });
    // this.loading.close();
    return img;
}

//查询持有人列表getHolders
export async function getHolders(address, _this){
    _this.tableLoading = true;
    var holders = [];
    try {
        await _this.$axios.post("/api/getHolders", {
            address:address,
            page:_this.page,
            offset:_this.offset,
        })
        .then((res) =>{
            holders = res.data;
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
    return holders;
}

//查询交易记录
export async function getTradeToTypeLast(address,last_id){
    this.tableLoading = true;
    var trade = [];
    try {
        await this.$axios.post("/api/getTradeToTypeLast", {
            account:localStorage.getItem("userAccount"),
            address:address,
            last_id:last_id,
        })
        .then((res) =>{
            trade = res.data;
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
    return trade;
}
//查询交易记录
export async function getTradeToTypeFirst(address,first_id){
    this.tableLoading = true;
    var trade = [];
    try {
        await this.$axios.post("/api/getTradeToTypeFirst", {
            account:localStorage.getItem("userAccount"),
            address:address,
            first_id:first_id,
        })
        .then((res) =>{
            trade = res.data;
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
    return trade;
}

//查询交易记录
export async function getTrade(name, _this){
    _this.tableLoading = true;
    var trade = [];
    try {
        await _this.$axios.post("/api/getTrade", {
            account:localStorage.getItem("userAccount"),
            name:name,
            page:_this.page,
            offset:_this.offset,
        })
        .then((res) =>{
            trade = res.data;
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
    return trade;
}
//查询订单getOrder
export async function getOrderList(name){
    try {
        this.$axios.post("/api/getOrderList", {
            account:localStorage.getItem("userAccount"),
            type:this.orderTypeValue,
            status:this.orderStatusValue,
            page:this.orderPage,
            offset:this.orderOffset,
        })
        .then((res) =>{
            this.orderList = res.data.data;
            this.orderCount = res.data.count;
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
}

//查询某个代币挂单列表
export async function getPlaceOrder(address,sort, _this){
    _this.loading = _this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    var list = [];
    try {
        await _this.$axios.post("/api/getPlaceOrder", {
            address:address,
            sort:sort,
            page:_this.page,
            offset:_this.offset,
        })
        .then((res) =>{
            list = res.data;
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
    _this.loading.close();
    return list;
}

//查询代币交易记录getTokenTrade
export async function getTokenTrade(name, _this){
    if(_this.page == 1){
        _this.load = _this.$loading({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }
    
    var list = [];
    try {
        await _this.$axios.post("/api/getTokenTrade", {
            name:name,
            page:_this.page,
            offset:_this.offset,
        })
        .then((res) =>{
            list = res.data;
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
    _this.load.close();
    return list;
}

//查询白名单铸造次数
export async function getWhite(address, tokenAddress){
    //判断是否为质押币，判断时间是否需要判断
    try {
        await this.$axios.post("/api/getWhite", {
            address:address,
            tokenAddress:tokenAddress,
        })
        .then((res) =>{
            this.white_mint_max = res.data.data;
            if(res.data.data==0){
                this.$dialog({
                    title: this.$t("white.t1"),
                    message: this.$t("white.t2"),
                    confirmButtonText: this.$t("white.t3"),
                    confirmButtonColor: '#FFAB09',
                }).then(() => {
                    //返回上一页
                    this.$router.go(-1);
                })
            }else if(res.data.data>0){
                this.$dialog({
                    title: this.$t("white.t4"),
                    message: this.$t("white.t5")+`<red>${res.data.data} ${this.$t("white.t8")}</red>`,
                    confirmButtonText: this.$t("white.t6"),
                    confirmButtonColor: '#FFAB09',
                })
            }else if(res.data.data==-2){
                if(res.data.djs>0){
                    //开启倒计时
                    let time = res.data.djs;
                    this.djs = formatTime(time,this);
                    this.djs_timer = setInterval(()=>{
                        time--;
                        if(time<=0){
                            clearInterval(this.djs_timer);
                            this.show_mongban = false;
                            //查询白名单
                            this.init();
                        }
                        this.djs = formatTime(time,this);
                    },1000)
                    this.show_mongban = true;
                }else{
                    this.show_mongban = false;
                }
            }
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
}

//查询限制代币开放时间
export async function tokenIsOpen(tokenAddress){
    //判断是否为质押币，判断时间是否需要判断
    try {
        await this.$axios.post("/api/tokenIsOpen", {
            tokenAddress:tokenAddress,
        })
        .then((res) =>{
            if(res.data.code==202){
                if(res.data.djs>0){
                    //开启倒计时
                    let time = res.data.djs;
                    this.djs = formatTime(time,this);
                    this.djs_timer = setInterval(()=>{
                        time--;
                        if(time<=0){
                            clearInterval(this.djs_timer);
                            this.show_mongban = false;
                            //查询白名单
                            this.init();
                        }
                        this.djs = formatTime(time,this);
                    },1000)
                    this.show_mongban = true;
                }else{
                    this.show_mongban = false;
                }
            }
            
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
}

//查询交易数据
export async function getTradeData(tokenAddress){
    try {
        await this.$axios.post("/api/getTradeData", {
            address:tokenAddress,
        })
        .then((res) =>{
            console.log(res.data.data);
            this.tradeData = res.data.data;
            this.chartData = {
				columns: ['ymd', 'price'],
				rows: res.data.data.day_price,
			}
        })
        .catch((error) =>{
            console.log(error);
        });
    } catch (error) {
        console.log(error);
    }
}