<template>
	<div class="container">
		<img src="../assets/banner.png" class="banner">
		<div class="introduce">
			<div class="deploy_box">
				<div class="logo_box">
					<img src="../assets/logo_round.png" class="logo">
					<span class="logo_span">3RC20</span>
				</div>
				<div class="top_btn">
					<div class="deploy" @click="open_deploy">
						<!-- <span>{{$t("home.t2")}}</span> -->
						<span>{{$t("home.t30")}}</span>
					</div>
					<div class="pledge" @click="toPledge">
						<span>{{$t("home.t31")}}</span>
					</div>
				</div>
			</div>
			<div class="introduce_info">
				<span>{{$t("home.t29")}}</span>
			</div>
		</div>
		
		<div class="sticky_box">
			<div class="menu">
				<div @click="changeTab" data-index="1" :class="{'active': tab==1}">{{$t("home.t3")}}</div>
				<div @click="changeTab" data-index="2" :class="{'active': tab==2}">{{$t("home.t4")}}</div>
				<div @click="changeTab" data-index="3" :class="{'active': tab==3}">{{$t("home.t5")}}</div>
				<div class="slider" :class="'tab'+tab"></div>
			</div>
			<div class="search" v-show="tab==1 && (tokens.length>0 || tokenParam.search!='')">
				<div>
					<input type="text" :class="{'has_txt':tokenParam.search.length>0}" placeholder="" @keyup="searchToken">
					<div class="icon">
						<img src="../assets/search.png" alt="" >
						<span>{{$t("home.t6")}}</span>
					</div>
				</div>
			</div>
			<div class="dropdown_menu" v-if="tab==3">
				<van-dropdown-menu>
					<van-dropdown-item v-model="orderTypeValue" :options='[{ text: this.$t("homeOption.t1"), value: 1 },{ text: this.$t("homeOption.t2"), value: 2 }]' @change="changeOrderType" />
					<van-dropdown-item v-model="orderStatusValue" :options='[{ text: this.$t("homeOption.t3"), value: -1 },{ text: this.$t("homeOption.t4"), value: 1 },{ text: this.$t("homeOption.t5"), value: 0 },{ text: this.$t("homeOption.t6"), value: 2 }]' v-if="isShowOption2" @change="getOrderList"/>
				</van-dropdown-menu>
			</div>
			<div class="pagination" v-if="tab==1 && tokenParam.count>tokenParam.offset" :class="{'showPagination':tokenParam.showPagination}">
				<van-pagination v-if="tokenParam.count>0" v-model="tokenParam.page" :total-items="tokenParam.count" :items-per-page="tokenParam.offset" :show-page-size="5" @change="changeTokensPage">
					<template #prev-text>
						<van-icon name="arrow-left" />
					</template>
					<template #next-text>
						<van-icon name="arrow" />
					</template>
					<template #page="{ text }">{{ text }}</template>
				</van-pagination>
			</div>

			<div class="pagination" v-if="tab==3 && orderCount>orderOffset" :class="{'showPagination':showPagination}">
				<van-pagination v-if="orderCount>0" v-model="orderPage" :total-items="orderCount" :items-per-page="orderOffset" :show-page-size="5" @change="changeOrderPage">
					<template #prev-text>
						<van-icon name="arrow-left" />
					</template>
					<template #next-text>
						<van-icon name="arrow" />
					</template>
					<template #page="{ text }">{{ text }}</template>
				</van-pagination>
			</div>
		</div>
		
		<div class="page" :class="{'page1':tokens.length>0}" v-if="tab==1">
			<div  v-if="tokens.length==0" class="none">
				<img src="../assets/no_record.png" alt="">
				<div>{{ $t("home.t14") }}</div>
			</div>

			<div class="tokens" v-for="(item,index) in tokens" :key="index" @click="toToken(item.name,item.address)">
				<div class="name">
					<div>
						<van-image round width="2.4rem" :src="item.tokenImg" />
						<span>{{item.name}}</span>
					</div>
					<img src="../assets/next.png" alt="">
				</div>
				<div class="progress">
					<div class="progress_out">
						<div class="progress_in" :style="'--progress:'+(item.mintedSupply / item.totalSupply * 100).toFixed(2)+'%'"></div>
					</div>
					<span>{{(item.mintedSupply / item.totalSupply * 100).toFixed(2)+'%'}}</span>
				</div>
				<div class="info">
					<div>{{$t("home.t7")}}：{{ $utils.handleAmount(item.totalSupply)}}</div>
					<div>{{$t("home.t8")}}：{{ $utils.handleAmount(item.mintedSupply)}}</div>
					<div>{{$t("home.t9")}}：{{ $utils.numToK(item.mintCount) }}</div>
					<div>{{$t("home.t10")}}：{{ item.createTime | formatTime}}</div>
				</div>
				<div class="btn_box">
					<div class="mint" v-if="is_show_mint(item)" @click.stop="toMint(item.name,item.address)">{{$t("home.t11")}}</div>
					<div class="trade" @click.stop="toMarket(item.name,item.address)">{{$t("home.t12")}}</div>
				</div>
			</div>
		</div>
		<div class="page page2" v-else-if="tab==2">
			<div class="myToken" v-for="(item, index) in myTokens" :key="index" @click="toBalance(item)">
				<div class="tokenName">
					<van-image round width="3rem" :src="item.tokenImg" />
					<span>{{ item.name }}</span>
				</div>
				<div class="tokenAmount">
					<div class="amount">
						<span>{{ $utils.numToK(item.balance) }}</span>
					</div>
					<div class="lockAmount" v-if="item.lockBalance>0">
						<img src="../assets/lock.png" alt="">
						<span>{{ $utils.numToK(item.lockBalance) }}</span>
					</div>
				</div>
			</div>
			<div  v-if="myTokens.length<=0" class="none">
				<img src="../assets/no_record.png" alt="">
				<div>{{ $t("home.t14") }}</div>
			</div>
		</div>
		<div class="page page3" v-else-if="tab==3">
			<div v-if="orderList.length>0" class="order_list">
				<div v-for="(item) in orderList" :key="item.id" class="order_item">
					<div class="order_top">
						<div class="name">
							<van-image round width="2.67rem" :src="item.tokenImg" />
							<span>{{ item.name }}</span>
						</div>
						<div :class="'status status'+item.status">{{ handle_status(item.status) }}</div>
					</div>
					<div class="order_info">
						<!-- <div class="hash">
							<span>{{ $t("home.t15") + item.hash | handle_hash }}</span>
							<img @click="copy_hash(item.hash)" class="copy" src="../assets/copy.png" alt="">
						</div> -->
						<div class="hex" @click="toHash(item.hash)">
							<span class="hash">{{ $t("home.t15") }}<span>{{ item.hash | handle_hash }}</span></span>
							<img class="copy" src="../assets/to.png" alt="">
						</div>
						<div>{{ $t("home.t16") + $utils.int2hex(item.orderId,10) }}</div>
						<div class="hash" v-if="orderTypeValue==2">
							<span>{{ $t("home.t17") + item.from | handle_hash }}</span>
							<img @click="copy_hash(item.from)" class="copy" src="../assets/copy.png" alt="">
						</div>
						<div class="hash" v-if="orderTypeValue==1 && item.status==2">
							<span>{{ $t("home.t18") + item.to | handle_hash }}</span>
							<img @click="copy_hash(item.to)" class="copy" src="../assets/copy.png" alt="">
						</div>
						<div>{{ $t("home.t19") }} {{ item.timestamp | formatTime }}</div>
						<div>{{ $t("home.t20") + $utils.handleAmount(item.amount) }} {{ item.name }}</div>
						<div>{{ $t("home.t21") + $utils.handleAmount(item.spend) }} 3T</div>
					</div>
					<van-button v-if="item.status=='1' || item.status=='3'" class="btn" size="small" color="#262626" round type="info" @click="openCancel(item)" :loading="item.loading">
						<span>{{ $t("home.t22") }}</span>
					</van-button>
				</div>
			</div>
			<div v-else class="none">
				<img src="../assets/no_record.png" alt="">
				<div>{{ $t("home.t23") }}</div>
			</div>
		</div>

		<!-- 确认撤单弹窗 -->
		<div class="pop" v-if="pop_cancel">
			<div class="pop_box" @click.stop="handlePopBoxClick">
				<div class="title">{{ $t("market.t13") }}</div>
				<div class="all">
					<span>{{ $t("market.t7") }}</span>
				</div>
				<div class="price_box" v-loading="FeeLoading">
					<div class="fee_title">{{ $t("market.t9") }}</div>
					<div class="fee_amount">{{ $utils.numToK(orderFee,9,2) }} 3T</div>
				</div>
				<div class="item">
					<div class="label df">
						<div>
							<span>{{ $t("deploy.t13") }}</span>
							<van-icon name="info" color="#FFAB09" size="1.17rem" @click="info" />
						</div>
						<span class="balance">{{ $t("market.t10") }}{{ $utils.numToK(TTTbalance,4) }} 3T</span>
					</div>
					<div class="show_txt">
						<div>{{ authorizeToMain }} 3T</div>
						<div class="authorize" @click="approve3T" v-if="orderFee>authorizeToMain">{{ $t("deploy.t14") }}</div>
					</div>
				</div>
				<div class="btn_box">
					<van-button @click="closeCancel" class="cancel" color="#E6E6E6">
						<span>{{ $t("market.t11") }}</span>
					</van-button>
					<van-button @click="comfirmCancelOrder" :disabled="authorizeToMain<orderFee" class="confirm" color="#F19F01">
						<span>{{ $t("market.t12") }}</span>
					</van-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bigInt from 'big-integer';
//导入eth_methods
import { getMyTokens,cancelOrder,approveToMain,getOrderFee,get3TAllowance,get3Tbalance} from '../utils/eth_methods.js';
import { getHome,getOrderList,getTokenList} from '../utils/api.js';

export default {
	data() {
		return {
			userAccount: '',		//用户账户
			tab:1,					//菜单页
			
			//3RC20项目
			tokenParam: {
				search: '',
				page: 1,
				offset: 100,
				count: 0,
				showPagination: false,
			},
			tokens:[],					//铭文币列表

			//我的3RC20
			myTokens:[],				//我有哪些铭文币
			
			//3RC20订单
			orderTypeValue: 1,			//订单类型值
			orderStatusValue: -1,		//订单状态值
			isShowOption2: true,		//是否显示第二个下拉框
			orderPage:1,				//订单页码
			orderOffset:100,				//订单每页数量	
			orderList:[],				//订单列表
			orderCount:0,				//总数
			cancelOrderLoading:false,	//撤单loading
			showPagination:false,		//是否显示分页

			//撤单弹窗
			pop_cancel:false,			//撤单弹窗
			orderItem:null,				//订单详情
			orderFee:0,					//撤单手续费
			authorizeToMain:0,			//授权额度
			TTTbalance:0,				//3T余额
			FeeLoading:false,			//手续费loading
			timer:null,					//定时器

		};
	},
	filters: {
		address(value) {
			if (!value) return '';
			return value.slice(0, 6) + '...' + value.slice(-4);
		},
		handle_hash(value) {
			return value.slice(0, 10) + '...' + value.slice(-10);
		},
		formatTime(value) {
			return new Date(value * 1000).toLocaleString();
		},
		
	},
	async mounted() {
		this.userAccount = localStorage.getItem('userAccount');
		//获取tab
		this.tab = localStorage.getItem('tab') || 1;
		//计算菜单滑块位置
		// this.calculateMenuLeft();
		window.addEventListener('scroll', this.handleScroll);
		if(this.tab==1 || this.tab==3){
			this.handleScroll();
		}
		if(this.userAccount){
			this.init();
		}
	},
	//离开页面时取消监听滚动
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	
	methods: {
		getHome,		//查询首页内容（所有代币、订单列表）
		getTokenList,	//查询所有铭文币
		getMyTokens,	//查询我有哪些铭文币
		getOrderList,	//查询订单列表
		cancelOrder,	//撤单
		handleTouchMove(e) {
			e.preventDefault();
		},
		//切换账户
		async changeAccount(account) {
			// this.$toast('新地址'+account);
			this.userAccount = account;
			if(this.userAccount){
				this.init();
			}
		},
		//初始化
		async init(){
			//查询我有哪些代币
			this.getMyTokens();
			//查询首页内容（所有代币、订单列表）
			this.getHome();
		},
		//计算菜单的left值
		calculateMenuLeft(){
			this.$nextTick(()=>{
				const rect = document.querySelector('.menu>div:nth-child('+this.tab+')').getBoundingClientRect();
				const left = rect.left + rect.width/2 - 20;
				document.querySelector('.slider').style.setProperty('--left', left + 'px');
			})
		},
		//切换菜单
		changeTab(e){
			this.tab = e.target.dataset.index;
			//查询对应内容
			if(this.tab==1){
				this.getTokenList();
			}else if(this.tab==2){
				this.getMyTokens();
			}else if(this.tab==3){
				this.getOrderList();
			}
			//记录tab,刷新页面时保持当前tab
			localStorage.setItem('tab',this.tab);
			const rect = e.target.getBoundingClientRect();
			//计算菜单的left值
			const left = rect.left + rect.width/2 - 20;
			document.querySelector('.slider').style.setProperty('--left', left + 'px');
			if(this.tab==1 || this.tab==3){
				this.showPagination = false;
				this.tokenParam.showPagination = false;
				this.handleScroll();
			}
		},
		//监听滚动，当菜单滚动到顶部时触发
		handleScroll(){
			//防抖
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				const rect = document.querySelector('.sticky_box').getBoundingClientRect();
				if(rect.top<=100){
					if(this.tab==3){
						this.showPagination = true;
					}else if(this.tab==1){
						this.tokenParam.showPagination = true;
					}
				}else{
					this.showPagination = false;
					this.tokenParam.showPagination = false;
				}
			}, 100);
		},
		//打开部署页面
		open_deploy(){
			this.$router.push({path:'/deploy'});
		},
		//搜索铭文币
		searchToken(e){
			const value = e.target.value;
			this.tokenParam.search = value;
			// 抖动
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.getTokenList();
			}, 300);
		},
		//分页
		changeTokensPage(value){
			this.tokenParam.page = value;
			this.getTokenList();
		},
		//跳转到铭文币详情
		toToken(name,address){
			this.$router.push({path:'/tokens',query:{name,address}});
		},
		//是否显示铸造按钮
		is_show_mint(item){
			if(!item.mintedSupply || bigInt(item.mintedSupply).value==bigInt(item.totalSupply).value){
				return false;
			}
			return true;
		},
		//跳转铸造页面
		toMint(name,address){
			this.$router.push({path:'/mint',query:{name,address}});
		},
		//跳转交易市场
		toMarket(name,address){
			this.$router.push({path:'/market',query:{name,address}});
		},
		//处理金额
		handle_amount(amount){
			let value = bigInt(amount) / 10 ** this.decimals;
			//获取value的小数位数
			let decimals = value.toString().split('.')[1];
			return value.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits: 2 }) + (decimals ?  '.'+decimals : '');
		},
		//跳转到余额页面
		toBalance(item){
			this.$router.push({path:'/balance',query:{address:item.address,name:item.name}});
		},
		//监听下拉菜单
		changeOrderType(value){
			if(value==1){
				this.isShowOption2 = true;
			}else{
				this.isShowOption2 = false;
			}
			this.getOrderList();
		},
		//处理订单状态
		handle_status(value) {
			switch (value) {
				case '0':
					return this.$t("home.t26");
				case '1':
					return this.$t("home.t25");
				case '2':
					return this.$t("home.t27");
				case '3':
					return this.$t("home.t24");
				default:
					return '';
			}
		},
		//复制hash
		copy_hash(hash){
			//点击复制hash
			this.$copyText(hash).then(() => {
				this.$toast(this.$t("home.t28"));
			});
		},
		//分页
		changeOrderPage(value){
			this.orderPage = value;
			this.getOrderList();
		},
		//---撤单---
		//点击弹窗阻止冒泡
		handlePopBoxClick(event){
			event.stopPropagation();
		},
		//说明
		info() {
			this.$dialog({
				title: this.$t("deploy.t19"),
				message: this.$t("deploy.t20"),
				confirmButtonText: this.$t("deploy.t21"),
				confirmButtonColor: '#FFAB09',
			});
		},
		//授权3T代币
		approveToMain,
		approve3T() {
			this.approveToMain(this.orderFee);
		},
		//打开撤单弹窗
		getOrderFee,
		get3TAllowance,
		get3Tbalance,
		async openCancel(item){
			this.pop_cancel = true;
			this.orderItem = item;
			//查询挂单手续费率
			this.orderFee = await this.getOrderFee();
			//定时查询订单手续费
			this.orderTimer && clearInterval(this.orderTimer);
			this.orderTimer = setInterval(async ()=>{
				// 查询订单手续费
				this.orderFee = await this.getOrderFee();
			},10000)
			//查询授权额度
			this.authorizeToMain = await this.get3TAllowance();
			//查询3T余额
			this.TTTbalance = await this.get3Tbalance();
		},
		//关闭弹窗
		closeCancel(){
			this.pop_cancel = false;
			//reset
			this.orderItem = null;
			clearInterval(this.orderTimer)
		},
		//确认撤单
		comfirmCancelOrder(){
			this.cancelOrder(this.orderItem);
		},
		//--撤单---//
		toPledge(){
			this.$router.push({path:'/pledge'});
		},
		toHash(hash){
			//跳转到hash
			window.open('https://scan.3tlab.xyz/tx/' + hash);
		}
	},
	
}
</script>

<style scoped lang="scss">
.container{
	height: auto;
	position: absolute;
}
.header{
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 1rem 1.25rem;
	.logo{
		width:3.71rem;
	}
	.wallet{
		display: flex;
		align-items: center;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: fit-content;
		img{
			width: 1.33rem;
		}
		span{
			margin:0 0.42rem;
			font-family: PingFang SC;
			font-size: 1.17rem;
			font-weight: bold;
			color: #000000;
		}
	}
	.language{
		width: 5.08rem;
		height: 2rem;
		background: #FFFFFF;
		border: 1px solid #000000;
		border-radius: 1rem;
		display: flex;
		align-items: center;
		padding: 0 0.17rem;
		img{
			border-radius: 50%;
		}
		span{
			margin: 0 auto;
			font-size: 1.17rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #000000;
			line-height: 2.08rem;
		}
	}
}
.banner{
	width: 100%;
}
.introduce{
	position: relative;
	margin: 0 auto;
	width: calc(100% - 2.5rem);
	background: #FFFFFF;
	border-radius: 0.83rem;	
	margin-top: -6.87rem;
	z-index: 1;

	.deploy_box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 1.25rem;
		padding: 1.25rem 0;
		border-bottom: 1px solid #E6E6E6;
		.logo_box{
			display: flex;
			align-items: center;
			.logo{
				width: 3.67rem;
			}
			.logo_span{
				font-size: 1.33rem;
				font-family: PingFang SC;
				font-weight: bold;
				color: #000000;
				line-height: 1.5rem;
				margin-left: 0.54rem;
			}
		}
		
		.deploy,.pledge{
			// width: 9.92rem;
			width: 6.67rem;
			height: 2.67rem;
			background: #262626;
			border-radius: 1.33rem;
			display: flex;
			align-items: center;
			justify-content: center;
			span{
				font-size: 1.17rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 2.08rem;
			}
		}
		.pledge{
			margin-left: 0.83rem;
			background: #F19F01;
		}
		.top_btn{
			display: flex;
		}
	}

	.introduce_info{
		padding: 1.13rem 1.29rem 1.96rem;
		font-size: 1rem;
		font-family: PingFang SC;
		color: #000000;
		line-height: 1.5rem;
	}
	.toPledge{
		margin: 0 auto;
		width: 9.92rem;
		height: 2.67rem;
		background: #262626;
		border-radius: 1.33rem;
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			font-size: 1.17rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 2.08rem;
		}
	}
}
.sticky_box{
	position: -webkit-sticky; /* 兼容性：Chrome, Safari, Edge */
	position: sticky;
	top: 0; /* 粘附到顶部 */
	background-color: #f2f2f2; /* 你可以根据需要设置背景颜色 */
	z-index: 9;
	.search{
		margin: 0.88rem 1.25rem 0;
		height: 3rem;
		// background: #FFFFFF;
		border-radius: 0.42rem;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding-bottom: 0.88rem;
		>div{
			width: 100%;
			height: 100%;
			background: #FFFFFF;
			border-radius: 0.42rem;
			position: relative;
			display: flex;
			align-items: center;
		}
		input{
			position: absolute;
			width: calc(100% - 3.8rem);
			height: 3.25rem;
			background: none;
			// border-radius: 0.42rem;
			border: none;
			padding: 0 1.3rem 0 2.5rem;
			z-index: 2;
			font-size: 1.17rem;
		}
		input::placeholder{
			font-size: 1rem;
			font-family: PingFang SC;
			color: #808080;
			line-height: 3.25rem;
		}
		input:focus{
			outline: none;
		}
		input:focus + .icon {
			transform: translateX(calc(-50vw + 2.75rem));
			// transform: translateX(calc(-50vw + 4.20rem));
		}
		input:focus + .icon span{
			display: none;
		}
		//input有值时
		input.has_txt + .icon{
			transform: translateX(calc(-50vw + 2.75rem));
		}
		input.has_txt + .icon span{
			display: none;
		}
		.icon{
			position: absolute;
			display: flex;
			align-items: center;
			transition: 300ms;
			transform: translateX(0px);
			z-index: 1;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			justify-content: center;
			align-items: center;
			img{
				width: 1.25rem;
				margin-right: 0.5rem;
			}
			span{
				font-size: 1.17rem;
				font-family: PingFang SC;
				color: #808080;
				line-height: 2.08rem;
			}
		}
		
	}
	.dropdown_menu{
		padding: 0.75rem 1.25rem;
	}
	.pagination{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		padding: 12px 1.25rem;
		background: #fff;
		box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.1);
		transition: 300ms;
		transform: translateY(100%);
	}
	.showPagination{
		transform: translateY(0);
	}
}
.menu{
	display: flex;
	border-bottom: 1px solid #D9D9D9;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 2rem;
	// margin-top: 0.75rem;
	padding: 1.65rem 2rem 0.9rem;
	position: relative;
	user-select: none;
	
	>div{
		font-size: 1.17rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #808080;
		line-height: 2.08rem;
	}
	
	>div.active{
		color: #000000;
	}

	.slider{
		content: "";
		width: 40px;
		height: 0.25rem;
		background: #FFAB09;
		position: absolute;
		left: 0;
		bottom: 0;
		transition: 0.3s;
		//移动left ,left来自于父元素
		// transform: translateX(var(--left));
	}
	
	.slider.tab1{
		transform: translateX(2.9rem);
	}
	.slider.tab2{
		transform: translateX(14rem);
	}
	.slider.tab3{
		transform: translateX(25.4rem);
	}
	& div:active{
		background: none;
	}
}
.page{
	// overflow: hidden;
    // height: calc(100vh - 4.75rem);
    min-height: calc(100vh - 8rem);
	padding-bottom: 3.25rem;
	.none{
		text-align: center;

		img{
			width: 60%;
		}
		div{
			margin-top: -24px;
			font-size: 18px;
			color: #999;
		}
	}
}
.page1{
	min-height: calc(100vh - 12.6417rem);
	.tokens{
		margin: 0 1.25rem;
		background: #FFFFFF;
		border-radius: 0.42rem;
		padding: 1.25rem;
		margin-bottom: 0.83rem;
		.name{
			display: flex;
			justify-content: space-between;
			align-items: center;
			span{
				font-size: 1.5rem;
				font-family: PingFang SC;
				font-weight: bold;
				color: #000000;
				line-height: 2.08rem;
			}
			img{
				width: 1.2rem;
			}
			>div{
				display: flex;
				align-items: center;
				>span{
					margin-left: 0.5rem;
				}
			}
		}
		.progress{
			display: flex;
			align-items: center;
			margin-top: 0.34rem;
			.progress_out{
				flex-grow: 1;
				position: relative;
				height: 0.42rem;
				background: #E5E5E5;
				border-radius: 0.21rem;
				overflow: hidden;
				margin-right: 1.46rem;
			}
			.progress_in{
				position: absolute;
				background: #E4B510;
				border-radius: 0.21rem;
				transition: 300ms;
				height: 100%;
				width: var(--progress);
			}
			span{
				font-size: 1rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #F19F01;
				line-height: 2.08rem;
			}
		}
		.info{
			font-size: 1rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 1.8rem;
		}
		.btn_box{
			display: flex;
			font-size: 1.17rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 2.08rem;
			margin-top: 1.2rem;
			.mint,.trade{
				width: 8.33rem;
				height: 2.67rem;
				border-radius: 1.33rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.mint{
				background: #262626;
				margin-right: 0.83rem;
			}
			.trade{
				background: #F19F01;
			}
		}
	}
	
	.pop{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0,0,0,0.5);
		z-index: 99;
		.pop_box{
			margin: auto 1.25rem;
			height: fit-content;
			background: #FFFFFF;
			border-radius: 0.83rem;
			position: absolute;
			padding: 1.8rem 1.3rem;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			.title{
				text-align: center;
				font-size: 1.5rem;
				font-family: PingFang SC;
				font-weight: bold;
				color: #000000;
				line-height: 2.08rem;
			}
			.item{
				margin-bottom: 1.38rem;
				.label{
					font-size: 1rem;
					font-family: PingFang SC;
					color: #000000;
					margin-bottom: 0.42rem;
				}
				input{
					width: calc(100% - 2.6rem);
					height: 3.25rem;
					background: #F2F2F2;
					border-radius: 0.42rem;
					border: none;
					padding: 0 1.3rem;
				}
				input::placeholder{
					font-size: 1rem;
					font-family: PingFang SC;
					color: #808080;
					line-height: 3.25rem;
				}
				input:focus{
					outline: none;
				}
				.small{
					font-size: 0.83rem;
					font-family: PingFang SC;
					color: #808080;
					line-height: 1.5rem;
				}
				.error{
					font-size: 0.83rem;
					font-family: PingFang SC;
					color: #FF0000;
					line-height: 1.5rem;
				}
			}
			.btn{
				width: 26.25rem;
				height: 3.25rem;
				background: #262626;
				border-radius: 1.63rem;
				font-size: 1.33rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 2.08rem;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 2.96rem;
			}
		}
	}
	.allowance{
		font-size: 1rem;
		font-family: PingFang SC;
		color: #FFAB09;
		line-height: 2.08rem;
		display: flex;
		justify-content: flex-end;
	}
}
.page2{
	min-height: calc(100vh - 8.75rem);
	.myToken{
		background: #FFFFFF;
		border-radius: 0.42rem;
		// margin-top: 0.83rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem 1.25rem;
		margin: 0 1.25rem;
		margin-top: 0.75rem;
		&:active{
			background: #fff;
		}
		// &:not(:last-child){
		// 	border-bottom: 1px solid #E6E6E6;
		// }
		.tokenName{
			display: flex;
			align-items: center;
			span{
				font-size: 1.5rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #000000;
				line-height: 2.08rem;
				margin-left: 0.83rem;
			}
		}
		.tokenAmount{
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			font-size: 1.2rem;
			font-family: monospace;
			// font-weight: bold;
			color: #000000;
			// line-height: 2.08rem;

			.lockAmount{
				display: flex;
				align-items: center;
				margin-top: 4px;
				img{
					width: 1rem;
					margin-right: 0.4rem;
				}
			}
			
		}
	}
	.toPledge{
		width: 9.92rem;
		height: 2.67rem;
		background: #262626;
		border-radius: 1.33rem;
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			font-size: 1.17rem;
			font-family: PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 2.08rem;
		}
	}
}
.page3{
	min-height: calc(100vh - 18rem);
    padding-bottom: 94px;
	.order_list{
		padding: 0 1.25rem;
		.order_item:not(:last-child){
			margin-bottom: 0.83rem;
		}
		.order_item{
			background: #FFFFFF;
			border-radius: 0.42rem;
			
			padding: 0 1.25rem;
			position: relative;
			.order_top{
				height: 3.67rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #E6E6E6;
				.name{
					display: flex;
					align-items: center;
					span{
						margin-left: 0.58rem;
						font-size: 1.2rem;
						font-weight: bold;
						color: #000000;
						line-height: 2.08rem;
					}
				}
				.status{
					font-size: 1.17rem;
				}
				.status0{
					color: #808080;	//已撤单
				}
				.status1{
					color: #F19F01;	//挂单中
				}
				.status2{
					color: #00B050;	//已成交
				}
				.status3{
					color: #808080;	//撤单中
				}
			}
			
			.order_info{
				padding-top: 0.42rem;
				padding-bottom: 1.3rem;
				font-size: 1rem;
				color: #000000;
				line-height: 1.76rem;
				.hex{
					display: flex;
					align-items: center;
					.copy{
						width: 1rem;
						margin-left: 0.3rem;
					}
					>span>span{
						color: #4395ff;
					}
				}
				.hash{
					display: flex;
					align-items: center;
					.copy{
						width: 1rem;
						margin-left: 0.3rem;
					}
				}
				
			}
			.btn{
				width: 7rem;
				height: 2.67rem;
				background: #262626;
				border-radius: 1.33rem;
				position: absolute;
				right: 1.25rem;
				bottom: 1.75rem;
				span{
					font-size: 1.17rem;
					color: #FFFFFF;
					white-space: 1px;
				}
			}
		}
	}
	
}
.pop{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);
	z-index: 2000;
	.pop_box{
		margin: auto 1.25rem;
		height: fit-content;
		background: #FFFFFF;
		border-radius: 0.83rem;
		position: absolute;
		padding: 1.8rem 1.3rem;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		.title{
			text-align: center;
			font-size: 1.5rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #000000;
			line-height: 2.08rem;
		}
		.item{
			margin-top: 1.25rem;
			.label{
				font-size: 1rem;
				font-family: PingFang SC;
				color: #000000;
				margin-bottom: 0.42rem;

				&.df{
					display: flex;
					align-items: center;
					justify-content: space-between;
					>div{
						display: flex;
						align-items: center;
						span{
							margin-right:5px;
						}
					}
				}
				
			}
			input{
				width: calc(100% - 2.6rem);
				height: 3.25rem;
				background: #F2F2F2;
				border-radius: 0.42rem;
				border: none;
				padding: 0 1.3rem;
			}
			input::placeholder{
				font-size: 1rem;
				font-family: PingFang SC;
				color: #808080;
				line-height: 3.25rem;
			}
			input:focus{
				outline: none;
			}
			.small{
				font-size: 0.83rem;
				font-family: PingFang SC;
				color: #808080;
				line-height: 1.5rem;
			}
			.error{
				font-size: 0.83rem;
				font-family: PingFang SC;
				color: #FF0000;
				line-height: 1.5rem;
			}
			.show_txt {
				display: flex;
				align-items: center;
				justify-content: space-between;
				word-break: break-all;
				width: calc(100% - 2.6rem);
				line-height: 1.4rem;
				// min-height: 3.25rem;
				background: #F2F2F2;
				border-radius: 0.42rem;
				border: none;
				padding: 1.04rem 1.3rem;

				.authorize {
					color: #FFAB09;
					font-size: 1rem;
				}
			}
		}
		.all{
			margin-top: 0.88rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&>span:nth-child(1){
				font-size: 0.83rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #000000;
				line-height: 2.08rem;
			}
			&>span:nth-child(2){
				font-size: 1rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: #F19F01;
				line-height: 2.08rem;
			}
		}
		.tips{
			font-size: 0.83rem;
			color: #9E9E9E;
			line-height: 1.25rem;
		}
		.btn_box{
			margin-top: 1.63rem;
			display: flex;
			justify-content: space-between;

			
			>div{
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.cancel{
				width: 12.54rem;
				height: 3.25rem;
				// background: #E6E6E6;
				border-radius: 1.63rem;
				color: #000000;
				span{
					font-size: 1.33rem;
					color: #000000;
				}
			}
			.confirm{
				width: 12.54rem;
				height: 3.25rem;
				// background: #F19F01;
				border-radius: 1.63rem;
				color: #FFFFFF;
				span{
					font-size: 1.33rem;
					color: #fff;
				}
			}
			span{
				padding: 0 0.2rem;
				font-size: 1.1rem;
			}
		}
		.price_box {
			margin-bottom: 1.38rem;
			height: 6.25rem;
			background: url('../assets/price_bj.png') no-repeat;
			background-size: 100% 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			padding-left: 1.3rem;

			.fee_title {
				font-size: 1rem;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #000000;
				line-height: 2.08rem;
			}

			.fee_amount {
				margin-top: 0.2rem;
				font-size: 1.83rem;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #FFAB09;
				line-height: 2.08rem;
			}
		}
	}
}
.mongban{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	background: rgba(0,0,0,.5);
	display: flex;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	font-size: 1.4rem;
	line-height: 2.3rem;
}
</style>
<style>
.el-loading-mask {
    background-color: rgb(255 255 255 / 50%);
}
</style>