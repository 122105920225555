import Web3 from 'web3';
import int2hex from '../utils/int2Hex';
const bigInt = require('big-integer');

import TTTcontractABI from '../../abi/TTTcontractABI.json';
import MainContractABI from '../../abi/MainContractABI.json';
import ChildrenContractABI from '../../abi/ChildrenContractABI.json';
import PledgeContractABI from '../../abi/PledgeContractABI.json';
import MarketContractABI from '../../abi/MarketContractABI.json';


// 3T测试链
// const TTTcontractAddress = '0xFDCCe1e8685dD6188a71a0De22A31b87af4B6016';		//3T测试链
// const MarketContractAddress = '0x3B00592779f09E808261967817c47cc274deca99';		//市场合约地址
// const PledgeContractAddress = '0x5B12234AcE30ee31B753b32155C6879aF7C7c798';		//质押合约地址（代理地址）
// const MainContractAddress = '0x389Fc2e8473a07Ca04Ef16629142a58A305E2c88';		//主合约地址
// const PledgeTokenAddress = '0xd0d7484298f50d0f94d76da9ecebc6cf9d24432f';		//质押币地址
// const targetChainId = int2hex(91196, 1)	//3T测试链

// 3T主链
const TTTcontractAddress = '0x7826E52F5E31f2D11140d4cba85657F7CaCBBd2a';		//3T主链
const MarketContractAddress = '0xC5cAe4A6cF9eCB09aEEe16C3F416dCEE63a13B5A';		//市场合约地址
const PledgeContractAddress = '0xA4d61a9EfB7137B1139c3782FedBcA45F3E1568e';		//质押合约地址
const MainContractAddress = '0xAF111e8ed00Ce421E7CBAb41ab7b11e83d9DFa7B';		//主合约地址
const PledgeTokenAddress = '0x64b19a99a4bef963bba077ffc1fe7d0f1db20763';		//质押币地址
const targetChainId = int2hex(81188, 1)	//3T主链

// const PledgeContractAddress = '0xb53706b19f34d79F46C9033851184088bF8df4FB';		//质押逻辑合约地址（原地址）
const decimals = 9;		//精度
var ethereum = null;
var web3 = null;

if (window.ethereum) {
    ethereum = window.ethereum;
    web3 = new Web3(window.ethereum);
} else if (window.web3) {
    ethereum = window.web3.currentProvider;
    web3 = new Web3(window.web3.currentProvider);
} else {
    this.$toast(this.$t('methods.t1'));
}

window.addEventListener('unhandledrejection', event => {
    console.log('ERROR', event);
    // 记录 Promise 拒绝到日志，而不是输出到控制台
    // console.error('Unhandled promise rejection:', event.reason);
    // 或者将错误信息发送到服务器进行记录
    // sendErrorToServer(event.reason);
});

//通过市场合约查询3T兑换率
export async function setFee() {
    try {
        const MainContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 调用合约的 deployToken 方法
        const result = await MainContract.methods.setFeeRate(
            parseInt(this.deployFee),
            parseInt(this.mintFee),
            parseInt(this.orderFee),
            parseInt(this.burnRate),
        ).send({
            from: this.userAccount, // 从当前账户发送交易
        });
        console.log(result)
    } catch (error) {
        console.log(error);
    }

}

//通过市场合约查询3T兑换率
export async function getExchangeRate() {
    try {
        const myContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        return await myContract.methods.getExchangeRate().call();
    } catch (error) {
        console.log(error)
    }
}
//字符串转换成为16进制
export function str2HexStr(str) {
    if (!str) {
        return '';
    }
    var hexCharCode = [];
    hexCharCode.push('0x');
    for (var i = 0; i < str.length; i++) {
        hexCharCode.push((str.charCodeAt(i)).toString(16));
    }
    return hexCharCode.join('');
}
//16进制转字符串
export function hexCharCodeToStr(hexCharCodeStr) {
    var trimedStr = hexCharCodeStr.trim();
    var rawStr =
        trimedStr.substr(0, 2).toLowerCase() === '0x'
            ? trimedStr.substr(2)
            : trimedStr;
    var len = rawStr.length;
    if (len % 2 !== 0) {
        alert('Illegal Format ASCII Code!');
        return '';
    }
    var curCharCode;
    var resultStr = [];
    for (var i = 0; i < len; i = i + 2) {
        curCharCode = parseInt(rawStr.substr(i, 2), 16); // ASCII Code Value
        resultStr.push(String.fromCharCode(curCharCode));
    }
    return resultStr.join('');
}
//将余额*10**decimals
export function handleAmountToWei(value) {
    return bigInt(value * 10 ** decimals);
}
// 封装连接钱包的逻辑
export async function connectWallet() {
    //获取第一次是否有账户
    var account = localStorage.getItem("userAccount");
    const chainId = await ethereum.request({
        method: 'eth_chainId'
    })
    if (chainId != targetChainId) {
        //切换至目标网络
        const result = await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: targetChainId }],
        }).then(async () => {
            // 获取用户账户
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            this.userAccount = accounts[0];
            localStorage.setItem("userAccount", accounts[0]);
        }).catch((error) => {
            this.userAccount = '';
            localStorage.setItem("userAccount", '');
            // 用户可能拒绝了切换网络请求
            this.$toast(this.$t('methods.t38'));
        })
    } else {
        // 获取用户账户
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        // console.log(accounts);
        this.userAccount = accounts[0];
        localStorage.setItem("userAccount", accounts[0]);
    }

    if (!this.userAccount) {
        this.$dialog({
            title: '未获取到钱包账户',
            message: '请刷新页面重新签名授权',
            confirmButtonText: '刷新页面',
            confirmButtonColor: '#FFAB09',
            cancelButtonText: '关闭Dapp',
            showCancelButton: true,
        }).then(() => {
            //刷新页面
            window.location.reload();
        }).catch(() => {
            //关闭整个页面
            window.close()
            return;
        });
    } else {
        //判断账户是否变化
        if (account != this.userAccount) {
            this.$emit('custom-event', this.userAccount);
        }
    }

    // 监听账户变化事件
    ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
            this.userAccount = accounts[0];
            //写入本地存储
            localStorage.setItem("userAccount", accounts[0]);
            this.$emit('custom-event', accounts[0]);
        } else {
            // 用户可能注销了账户，你可以在这里进行相应的处理
            this.$toast(this.$t('methods.t2'));
        }
    });
}
// 重写拉起签名消息
export async function signMessage(message) {
    try {
        const signature = await window.ethereum.request({
            method: 'personal_sign',
            params: [message, localStorage.getItem("userAccount")],
        });
        console.log('Signature:', signature);

        // 在这里你可以处理签名后的操作
    } catch (error) {
        console.error('Error signing message:', error.message);
    }
}
//获取用户账户地址
export async function getUserAccount() {
    try {
        // 获取用户账户
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        this.userAccount = accounts[0];
        localStorage.setItem("userAccount", accounts[0]);
    } catch (error) {
        console.log(error)
    }
}
//查询3T币余额
export async function get3Tbalance() {
    try {
        //链接3T合约
        const TTTcontract = new web3.eth.Contract(TTTcontractABI, TTTcontractAddress);
        //查询3T代币
        const result = await TTTcontract.methods.balanceOf(this.userAccount).call();
        return bigInt(result) / 10 ** decimals;
    }
    catch (error) {
        console.log(error);
        return 0;
    }
}
//查询挂单数量和限制
export async function getOrderNumMax() {
    try {
        const myContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        const max = parseInt(await myContract.methods.orderMax().call());
        const num = parseInt(await myContract.methods.getUserOrderLength(localStorage.getItem("userAccount")).call());
        console.log(max)
        console.log(num)
        if (num >= max) {
            this.$toast(this.$t('methods.t48') + max);
            return false;
        }
    } catch (error) {
        console.log(error)
    }
    return true;
}
//查询挂单手续费
export async function getListingFeeRate() {
    console.log('getListingFeeRate')
    try {
        const myContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        return bigInt(await myContract.methods.getOrderFee().call()) / 10 ** decimals;
    } catch (error) {
        console.log(error)
    }
}
//查询订单手续费
export async function getOrderFee(isHandle = true) {
    this.FeeLoading = true;
    try {
        const MainContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        setTimeout(() => {
            this.FeeLoading = false;
        }, 500)
        let amount = bigInt(await MainContract.methods.getOrderFee().call());
        if (isHandle) {
            amount = amount / 10 ** decimals;
        }
        return amount;
    } catch (error) {
        setTimeout(() => {
            this.FeeLoading = false;
        }, 500)
        console.log(error);
    }
}
//查询所有铭文币
export async function getAllTokens(page = 1, offset = 10) {
    try {
        const myContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        const result = await myContract.methods.getTokens(page, offset).call();
        if (result.length == 0) {
            this.tokenParam.page--;
            return;
        }
        // 循环遍历每个代币的地址
        var arr = [];
        for (var i = 0; i < result.length; i++) {
            const ChildrenContract = new web3.eth.Contract(ChildrenContractABI, result[i]);
            // 替换成你的智能合约中的 getAllTokens 方法
            const tokeninfo = await ChildrenContract.methods.getTokenInfo().call();
            tokeninfo.address = result[i];
            //处理bigInt
            var decimal = bigInt(tokeninfo.decimal);
            var temp = {
                totalSupply: bigInt(tokeninfo.totalSupply) / 10 ** decimal,
                mintedSupply: bigInt(tokeninfo.mintedSupply) / 10 ** decimal,
                maxMintPerTx: bigInt(tokeninfo.maxMintPerTx) / 10 ** decimal,
                createTime: bigInt(tokeninfo.createTime),
                finishTime: bigInt(tokeninfo.finishTime),
                decimal: decimal,
                transactions: bigInt(tokeninfo.transactions),
                tokenImg: tokeninfo.tokenImg,
                owner: tokeninfo.owner,
                name: tokeninfo.name,
                address: result[i],
                holdersCount: tokeninfo.holdersCount,
                mintCount: tokeninfo.mintCount ? tokeninfo.mintCount : 0,
            }
            arr.push(temp);
        }
        this.tokens = arr;
    } catch (error) {
        console.log(error)
    }
}
//授权3T币给主合约
export async function approveToMain(amount) {
    console.log(amount)
    this.loading = this.$loading({
        lock: true,
        text: this.$t('methods.t3'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    try {
        const approveAmount = Web3.utils.toWei(amount, 'gwei');
        console.log(approveAmount)
        const TTTcontract = new web3.eth.Contract(TTTcontractABI, TTTcontractAddress);
        // 调用合约的 deployToken 方法
        const result = await TTTcontract.methods.approve(
            MainContractAddress,
            approveAmount,
        ).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
        });
        this.loading.close();
        this.authorizeToMain = await this.get3TAllowance(MainContractAddress);
        return result;
    }
    catch (error) {
        this.loading.close();
        if (error.code == 100) {
            this.$toast(this.$t('methods.t4'));
        }
        console.log(error.code, error.message);
    }
}
//通用授权3T额度,需传入被授权的合约地址，授权金额
export async function approve(ContractAddress, amount) {
    this.loading = this.$loading({
        lock: true,
        text: this.$t('methods.t3'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    try {
        const TTTcontract = new web3.eth.Contract(TTTcontractABI, TTTcontractAddress);
        // 调用合约的 deployToken 方法
        const result = await TTTcontract.methods.approve(
            ContractAddress,
            Web3.utils.toWei(amount, 'gwei'),
        ).send({
            from: this.userAccount, // 从当前账户发送交易
        });
        this.loading.close();
        this.get3TAllowance(ContractAddress);
        return result;
    }
    catch (error) {
        this.loading.close();
        if (error.code == 100) {
            this.$toast(this.$t('methods.t4'));
        }
        console.log(error.code, error.message);
    }
}
//查询3T代币的授权额度
export async function get3TAllowance() {
    try {
        //链接3T合约
        const TTTcontract = new web3.eth.Contract(TTTcontractABI, TTTcontractAddress);
        //查询3T代币
        const rawTokens = await TTTcontract.methods.allowance(this.userAccount, MainContractAddress).call();
        const rawTokensBigInt = bigInt(rawTokens);
        const tokens = rawTokensBigInt / (10 ** bigInt(decimals));
        return tokens;
    } catch (error) {
        console.log(error);
        return 0;
    }
}
//查询部署费
export async function getDeployFee() {
    this.deployFeeLoading = true;
    try {
        const MainContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        setTimeout(() => {
            this.deployFeeLoading = false;
        }, 500)
        return bigInt(await MainContract.methods.getDeployFee().call()) / 10 ** decimals;
    } catch (error) {
        setTimeout(() => {
            this.deployFeeLoading = false;
        }, 500)
        console.log(error);
    }
    // this.deployFeeLoading = true;
    // try {
    //     const MainContract = new web3.eth.Contract(MarketContractABI, MarketContractAddress);
    //     // 替换成你的智能合约中的 getAllTokens 方法
    //     setTimeout(()=>{
    //         this.deployFeeLoading = false;
    //     },500)
    //     return bigInt(await MainContract.methods.getSwapRate().call()) / 10 ** decimals;
    // } catch (error) {
    //     setTimeout(()=>{
    //         this.deployFeeLoading = false;
    //     },500)
    //     console.log(error);
    // }
}
//发行铭文币
export async function deployToken() {
    if (this.show_mongban) {
        this.$toast(this.$t('methods.t47'));
        return;
    }
    var loading = null;
    //检查deployTokenParam参数
    //移除空格
    this.deployTokenParam.name = this.deployTokenParam.name.replace(/\s+/g, "");
    if (!validateCreateTokenParams(this.deployTokenParam)) {
        this.$toast(this.$t('methods.t5'));
        return;
    }
    if (this.is_agree == false) {
        this.$toast(this.$t('methods.t6'));
        return;
    }
    try {
        //查看授权额度
        var tokens = await this.get3TAllowance(MainContractAddress);
        if (tokens < this.deployFee) {
            this.approveToMain(this.deployFee);
            return;
        }
        //判断名称是否被使用,调用合约的 isNameUsed 方法
        var isNameValid = await new web3.eth.Contract(MainContractABI, MainContractAddress).methods.isNameValid(this.deployTokenParam.name).call();
        if (!isNameValid) {
            this.$toast(this.$t('methods.t7'));
            return;
        }

        loading = this.$loading({
            lock: true,
            text: this.$t('methods.t8'),
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

        // 上传图片
        var uploadRes = await this.uploadImg(this.deployTokenParam.headImg, this.deployTokenParam.name);
        console.log(uploadRes.code)
        if (uploadRes.code != 200) {
            loading && loading.close();
            this.$toast(uploadRes.msg);
            return;
        }

        const myContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 调用合约的 deployToken 方法
        const result = await myContract.methods.deployToken(
            this.deployTokenParam.name,
            this.deployTokenParam.totalSupply,
            this.deployTokenParam.maxMintPerTx,
            uploadRes.data
        ).send({
            from: this.userAccount, // 从当前账户发送交易
        });

        loading && loading.close();
        if (result.transactionHash) {
            this.$toast(this.$t('methods.t9'));
            //关闭当前页面，跳转到代币详情页
            this.$router.replace({
                path: '/tokens',
                query: {
                    name: this.deployTokenParam.name,
                    address: result.events.OwnershipTransferred.address
                }
            });
        } else {
            this.$toast(this.$t('methods.t10'));
        }
    }
    catch (err) {
        loading && loading.close();
        if (err.code == 100) {
            this.$toast(this.$t('methods.t11'));
        }
        console.log(err.code, err.message);
    }
}
// 检查 deployToken 参数是否符合要求
export function validateCreateTokenParams(deployTokenParam) {
    var flag = true;
    let str = deployTokenParam.name;
    //移除空格
    str = str.replace(/\s+/g, "");
    // 规则1：长度为4
    const rule1 = str.length === 4;
    // 规则2：必须包含一个字母
    const rule2 = /[a-zA-Z]/.test(str);
    // 规则3：不允许纯数字
    const rule3 = /\D/.test(str);
    // 规则4：允许包含一个$符号
    const rule4 = (str.match(/\$/g) || []).length <= 1;
    if (rule1 && rule2 && rule3 && rule4) {
        deployTokenParam.name_error = false;
    } else {
        deployTokenParam.name_error = true;
        flag = false;
    }

    // 发行总量为正整数，最大为1万亿
    const totalSupply = parseInt(deployTokenParam.totalSupply);
    if (isNaN(totalSupply) || totalSupply <= 0 || totalSupply > 1000000000000) {
        deployTokenParam.totalSupply_error = true;
        flag = false;
    } else {
        deployTokenParam.totalSupply_error = false;
    }

    // 最大发行量为正整数，不超过总量
    const maxMintPerTx = parseInt(deployTokenParam.maxMintPerTx);
    if (isNaN(maxMintPerTx) || maxMintPerTx <= 0 || maxMintPerTx > totalSupply) {
        deployTokenParam.maxMintPerTx_error = true;
        flag = false;
    } else {
        deployTokenParam.maxMintPerTx_error = false;
    }
    //是否上传头像
    if (deployTokenParam.headImg == "") {
        deployTokenParam.headImg_error = true;
        flag = false;
    } else {
        deployTokenParam.headImg_error = false;
    }
    return flag;
}
// 详情页查询代币信息
export async function getTokenInfoToAddress(address) {
    try {
        const contract = new web3.eth.Contract(ChildrenContractABI, address);
        // 替换成你的智能合约中的 getAllTokens 方法 
        const result = await contract.methods.getTokenInfo().call();
        //处理bigInt
        this.tokenInfo = {
            totalSupply: bigInt(result.totalSupply) / 10 ** bigInt(result.decimal),
            mintedSupply: bigInt(result.mintedSupply) / 10 ** bigInt(result.decimal),
            maxMintPerTx: bigInt(result.maxMintPerTx) / 10 ** bigInt(result.decimal),
            decimal: bigInt(result.decimal),
            tokenImg: result.tokenImg,
        };
        this.maxMint = this.tokenInfo.maxMintPerTx;
    } catch (error) {
        console.log(error)
    }
}
// 详情页查询代币信息
export async function getTokensInfo(address, _this) {
    try {
        var loading = _this.$loading({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        const contract = new web3.eth.Contract(ChildrenContractABI, address);
        // 替换成你的智能合约中的 getAllTokens 方法 
        const result = await contract.methods.getTokenInfo().call();
        var tokeninfo = {
            totalSupply: bigInt(result.totalSupply) / 10 ** bigInt(result.decimal),
            mintedSupply: bigInt(result.mintedSupply) / 10 ** bigInt(result.decimal),
            maxMintPerTx: bigInt(result.maxMintPerTx) / 10 ** bigInt(result.decimal),
            createTime: new Date(bigInt(result.createTime) * 1000).toLocaleString(),
            finishTime: result.finishTime > 0 ? new Date(bigInt(result.finishTime) * 1000).toLocaleString() : 0,
            decimal: bigInt(result.decimal),
            transactions: bigInt(result.transactions),
            tokenImg: result.tokenImg,
            owner: result.owner,
            name: result.name,
            address: address,
            holdersCount: result.holdersCount,
            mintCount: result.mintCount ? result.mintCount : 0,
        }
        loading.close();
        return tokeninfo;
    } catch (error) {
        loading.close();
        console.log(error)
    }
}
//查询铸造费
export async function getMintFee() {
    this.FeeLoading = true;
    try {
        const MainContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        setTimeout(() => {
            this.FeeLoading = false;
        }, 500)
        // 替换成你的智能合约中的 getAllTokens 方法
        return bigInt(await MainContract.methods.getMintFee().call()) / 10 ** decimals;
    } catch (error) {
        setTimeout(() => {
            this.FeeLoading = false;
        }, 500)
        console.log(error);
    }
}
//铸造铭文币
export async function mintToken() {
    if (this.show_mongban) {
        this.$toast(this.$t('methods.t47'));
        return;
    }
    //判断是否需要限制铸造次数 -1：不做限制
    console.log(this.white_mint_max, this.white_mint_max, this.mintTokenParam.mintCount)
    if (this.white_mint_max != -1) {
        if (this.white_mint_max < this.mintTokenParam.mintCount) {
            this.$dialog({
                title: this.$t("white.t7"),
                message: this.$t("white.t5") + `<red>${this.white_mint_max}  ${this.$t("white.t8")}</red>`,
                confirmButtonText: this.$t("white.t6"),
                confirmButtonColor: '#FFAB09',
            })
            return;
        }
    }
    if (!this.mintTokenParam.maxMintPerTx) {
        this.$toast(this.$t('methods.t13'));
        return;
    }
    if (!this.mintTokenParam.mintCount) {
        this.$toast(this.$t('methods.t14'));
        return;
    }
    //每份不能超过限制
    if (this.mintTokenParam.maxMintPerTx > this.tokenInfo.maxMintPerTx) {
        this.$toast(this.$t('methods.t15'));
        return;
    }
    //总量不超剩余
    var minttotal = this.mintTokenParam.mintCount * this.mintTokenParam.maxMintPerTx;
    //剩余总量
    var last = this.tokenInfo.totalSupply - this.tokenInfo.mintedSupply;
    if (minttotal > last) {
        this.$toast(this.$t('methods.t16'));
        return;
    }
    //是否同意
    if (this.is_agree == false) {
        this.$toast(this.$t('methods.t17'));
        return;
    }

    try {
        this.loading = this.$loading({
            lock: true,
            text: this.$t('methods.t18'),
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        //查看授权额度
        var allowance = await this.get3TAllowance(MainContractAddress);
        if (allowance < this.mintFee) {
            this.$toast(this.$t('methods.t19'));
            this.approve(this.tokenAddress, this.mintFee);
            return;
        }

        const myContract = new web3.eth.Contract(ChildrenContractABI, this.tokenAddress);
        // 调用合约的 deployToken 方法
        const result = await myContract.methods.mint(
            this.mintTokenParam.maxMintPerTx * 10 ** decimals,
            this.mintTokenParam.mintCount
        ).send({
            from: this.userAccount, // 从当前账户发送交易
        });
        this.loading.close();
        if (result.transactionHash) {
            this.$toast(this.$t('methods.t20'));
            //刷新数据
            // this.init();
            //返回上一页
            setTimeout(() => {
                this.$router.go(-1);
            }, 500);
        } else {
            this.$toast(this.$t('methods.t21'));
        }
    }
    catch (error) {
        this.loading.close();
        if (error.code == 100) {
            this.$toast(this.$t('methods.t22'));
        } else {
            this.$toast(this.$t('methods.t46'));
        }
        console.log(error.code, error.message);
    }
}
//查询我有哪些铭文币
export async function getMyTokens() {
    try {
        const myContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        const result = await myContract.methods.getUserTokenBalances(localStorage.getItem("userAccount")).call();
        //bigInt
        var arr = [];
        for (var i = 0; i < result[0].length; i++) {
            var temp = {
                address: result[0][i],
                name: result[1][i],
                balance: bigInt(result[2][i]) / 10 ** decimals,
                lockBalance: bigInt(result[3][i]) / 10 ** decimals,
                tokenImg: result[4][i],
            }
            arr.push(temp);
        }
        this.myTokens = arr;
    } catch (error) {
        console.log(error)
    }
}
//查询代币余额
export async function getBalanceOf(address) {
    try {
        const contract = new web3.eth.Contract(ChildrenContractABI, address);
        // 替换成你的智能合约中的 getAllTokens 方法
        let account = localStorage.getItem("userAccount");
        const result = await contract.methods.getUserBalance(account).call();
        return {
            balance: bigInt(result[0]) / 10 ** decimals,
            lockBalance: bigInt(result[1]) / 10 ** decimals,
        };
    } catch (error) {
        console.log(error);
        return {
            balance: 0,
            lockBalance: 0,
        };
    }
}
//转账铭文币
export async function transferToken() {
    //检查参数
    if (this.transferError.to.error || this.transferError.amount.error) {
        this.$toast(this.$t('methods.t23'));
        return;
    }
    //将地址转换成小写
    var from = this.userAccount.toLowerCase();
    var to = this.transferTokenParam.to.toLowerCase();
    if (from == to) {
        this.transferError.to.error = true;
        this.transferError.to.msg = this.$t('methods.t24');
        return;
    }
    //如果转账金额之后为0，提示错误
    if (this.transferTokenParam.amount == (this.lockBalance + this.balance)) {
        this.$dialog({
            title: this.$t("methods.t63"),
            message: this.$t("methods.t64"),
            confirmButtonText: this.$t("methods.t65"),
            confirmButtonColor: '#FFAB09',
        })
        return;
    }

    try {
        this.transferLoading = true;
        const myContract = new web3.eth.Contract(ChildrenContractABI, this.address);
        // 调用合约的 deployToken 方法
        const transferAmount =  Web3.utils.toWei(this.transferTokenParam.amount, 'gwei');
        console.log(transferAmount)
        const result = await myContract.methods.transfer(
            this.transferTokenParam.to,
            transferAmount,
        ).send({
            from: this.userAccount, // 从当前账户发送交易
        });
        this.transferLoading = false;
        if (result.transactionHash) {
            this.$toast(this.$t('methods.t25'));
            //刷新数据
            this.init();
            //关闭弹窗
            setTimeout(() => {
                this.pop_transfer = false;
            }, 300);
        } else {
            this.$toast(this.$t('methods.t26'));
        }
    }
    catch (error) {
        this.transferLoading = false;
        if (error.code == 100) {
            this.$toast(this.$t('methods.t27'));
        }
        console.log(error.code, error.message);
    }
}
//挂单lockTokens
export async function placeOrder() {
    //检查参数
    if (this.placeOrderParam.amount > this.tokenAmount) {
        this.orderError.amount.error = true;
        this.orderError.amount.msg = this.$t('methods.t28');
        this.orderLoading = false;
        return;
    }
    if (this.placeOrderParam.amount <= 0) {
        this.orderError.amount.error = true;
        this.orderError.amount.msg = this.$t('methods.t29');
        this.orderLoading = false;
        return;
    }
    if (this.placeOrderParam.spend <= 0) {
        this.orderError.spend.error = true;
        this.orderError.spend.msg = this.$t('methods.t30');
        this.orderLoading = false;
        return;
    }
    //如果转账金额之后为0，提示错误
    if (this.placeOrderParam.amount == (this.lockBalance + this.balance)) {
        this.$dialog({
            title: this.$t("methods.t63"),
            message: this.$t("methods.t64"),
            confirmButtonText: this.$t("methods.t65"),
            confirmButtonColor: '#FFAB09',
        })
        this.orderLoading = false;
        return;
    }
    try {
        //查看授权额度
        var tokens = await this.get3TAllowance(MainContractAddress);
        if (tokens < this.orderFee) {
            this.$toast(this.$t('methods.t19'));
            this.approveToMain(this.orderFee);
            this.orderLoading = false;
            return;
        }

        this.loading_createToken = this.$loading({
            lock: true,
            text: this.$t('methods.t31'),
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        const myContract = new web3.eth.Contract(ChildrenContractABI, this.address);
       
        // 调用合约的 deployToken 方法
        const result = await myContract.methods.lockTokens(
            this.placeOrderParam.amount * 10 ** decimals,
            this.placeOrderParam.spend * 10 ** decimals,
        ).send({
            from: this.userAccount, // 从当前账户发送交易
        });

        this.loading_createToken.close();
        if (result.transactionHash) {
            this.$toast(this.$t('methods.t32'));
            this.init();
            //关闭弹窗
            setTimeout(() => {
                this.pop_order = false;
                clearInterval(this.orderTimer)
            }, 300);
        } else {
            this.$toast(this.$t('methods.t33'));
        }
        this.orderLoading = false;
    }
    catch (error) {
        this.loading_createToken.close();
        this.orderLoading = false;
        if (error.code == 100) {
            this.$toast(this.$t('methods.t34'));
        }
        console.log(error.code, error.message);
    }
}
//撤单cancelOrder
export async function cancelOrder(item) {
    //查看授权额度
    var tokens = await this.get3TAllowance(MainContractAddress);
    if (tokens < this.orderFee) {
        this.$toast(this.$t('methods.t19'));
        this.approveToMain(this.orderFee);
        return;
    }
    item.status = '3';  //撤单中
    item.loading = true;
    this.pop_cancel = false;
    try {

        const myContract = new web3.eth.Contract(ChildrenContractABI, item.address);
        // 调用合约的 deployToken 方法
        const result = await myContract.methods.unlockTokens(
            item.orderId
        ).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
        });
        if (result.transactionHash) {
            this.$toast(this.$t('methods.t35'));
            item.loading = false;
            //设置状态为撤单
            setTimeout(async () => {
                item.status = '0';  //撤单成功
                this.orderItem = null;
                clearInterval(this.orderTimer)
                //更新余额
                await this.getMyTokens().then((res) => {
                    this.myTokens = res;
                });
            }, 300);
        } else {
            this.$toast(this.$t('methods.t36'));
        }
    }
    catch (error) {
        item.status = '1';  //还原状态
        item.loading = false;
        if (error.code == 100) {
            this.$toast(this.$t('methods.t37'));
        }
        console.log(error.code, error.message);
    }
}
//查询购买手续费
export async function getBuyFeeRate() {
    try {
        const myContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
        // 替换成你的智能合约中的 getAllTokens 方法
        return await myContract.methods.getBuyFeeRate().call();
    } catch (error) {
        console.log(error)
    }
}
//查询订单状态
export async function getOrderStatus(item) {
    try {
        const myContract = new web3.eth.Contract(ChildrenContractABI, item.address);
        // 替换成你的智能合约中的 getAllTokens 方法
        return await myContract.methods.getLockOrder(item.orderId).call();
    } catch (error) {
        console.log(error)
    }
}
//购买
export async function buyOrder() {
    //查看授权额度
    var tokens = await this.get3TAllowance(MainContractAddress);
    if (tokens < this.buyFee) {
        this.$toast(this.$t('methods.t19'));
        this.approveToMain(this.orderFee);
        return;
    }
    var item = this.orderItem;
    //不能购买自己的挂单
    var account = localStorage.getItem("userAccount").toString().toLowerCase();
    var from = item.from.toString().toLowerCase();
    if (from == account) {
        this.$toast(this.$t('methods.t39'));
        return;
    }
    //查询余额是否足够
    if (this.TTTbalance < this.buyFee) {
        this.$toast(this.$t('methods.t40'));
        return;
    }
    this.loading = this.$loading({
        lock: true,
        text: this.$t('methods.t41'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    try {
        const myContract = new web3.eth.Contract(ChildrenContractABI, item.address);
        // 调用合约的 deployToken 方法
        const result = await myContract.methods.buy(
            item.orderId,
        ).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
        });
        this.loading.close();
        if (result.transactionHash) {
            this.$toast(this.$t('methods.t42'));
            //关闭弹窗
            setTimeout(() => {
                //刷新数据
                this.init();
                this.pop_buy = false;
                clearInterval(this.orderTimer)
            }, 300);
        } else {
            this.$toast(this.$t('methods.t43'));
        }
    }
    catch (error) {
        if (error.code == 100) {
            this.$toast(this.$t('methods.t44'));
        } else {
            this.$toast(this.$t('methods.t45'));
        }
        console.log(error.code, error.message);
    }
    this.loading.close();
}


//----质押合约----//
//查询质押页面数据
export async function getPledge() {
    let loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });

    try {
        const contract = new web3.eth.Contract(PledgeContractABI, PledgeContractAddress);
        let pool = await contract.methods.getPledge(MainContractAddress, localStorage.getItem("userAccount")).call();
        // console.log(pool)
        var result = {
            total: bigInt(pool.amount3T) / 10 ** decimals,  //奖池总量
            headcount: bigInt(pool.userCount),              //参与人数
            pledgeAmount: bigInt(pool.totalPledges) / 10 ** decimals,   //质押总量
            burnTotal: bigInt(pool.burnTotal) / 10 ** decimals,          //销毁总量
            userPledge: bigInt(pool.userPledges) / 10 ** decimals,  //我的质押
            pledgeTokenBalance: bigInt(pool.balance) / 10 ** decimals,  //质押币余额
            stage: bigInt(pool.stage),      //当前阶段
            start: new Date(parseInt(pool.start) * 1000).toLocaleString(),  //开始时间
            end: new Date(parseInt(pool.end) * 1000).toLocaleString(),      //结束时间
        }
        // console.log(result)
        this.pledge = result;
        this.isPledgeTime = await contract.methods.isPledgeTimeZone().call();    //是否在质押时间内
        loading.close();
    } catch (error) {
        loading.close();
        console.log(error)
    }
}
//查询奖池数据
export async function getPledgePool() {
    try {
        const contract = new web3.eth.Contract(PledgeContractABI, PledgeContractAddress);
        const oldTotal = this.pledge.total; //记录旧奖池
        const oldStage = this.pledge.stage; //记录旧阶段

        let pool = await contract.methods.getPledge().call();   //奖池数据
        let newStage = bigInt(pool[0]);
        //阶段变了刷新全部
        if (oldStage.value != newStage.value) {
            this.init();
            return;
        }

        this.pledge.stage = newStage;
        this.pledge.total = bigInt(pool[1]) / 10 ** decimals;
        this.pledge.headcount = bigInt(pool[2]);
        this.pledge.pledgeAmount = bigInt(pool[3]) / 10 ** decimals;
        console.log(pool)
        //奖励变了销毁总量才会变     //奖励变了阶段才有可能变
        if (oldTotal != this.pledge.total) {
            const MainContract = new web3.eth.Contract(MainContractABI, MainContractAddress);
            let burnTotal = await MainContract.methods.burnTotal().call(); //销毁总量
            this.pledge.burnTotal = bigInt(burnTotal) / 10 ** decimals;
        }
    } catch (error) {
        console.log(error)
    }
}
//我要质押
export async function comfirmPledge() {
    let pledgeAmount = this.pledgeAmount * 10 ** decimals;
    //不能有小数
    let xs = this.pledgeAmount.toString().split('.');
    if (xs[1] && xs[1].length > 2) {
        this.$toast(this.$t('methods.t66'));
        return;
    }
    //检查参数
    if (pledgeAmount <= 0) {
        // this.$toast('质押数量不能小于0');
        this.$toast(this.$t('methods.t49'));
        return;
    }
    //最小质押0.01
    if (pledgeAmount < 0.01 * 10 ** 9) {
        // this.$toast('最小质押数量为0.01');
        this.$toast(this.$t('methods.t50'));
        return;
    }
    //不能全部质押
    if (this.pledgeAmount == this.pledge.pledgeTokenBalance) {
        this.$dialog({
            title: this.$t("methods.t63"),
            message: this.$t("methods.t64"),
            confirmButtonText: this.$t("methods.t65"),
            confirmButtonColor: '#FFAB09',
        }).then(() => {
            this.pledgeAmount -= 0.01;
        })
        return;
    }
    this.pledgeLoading = true;
    try {
        //查询质押币余额
        var pledgeToken = new web3.eth.Contract(ChildrenContractABI, PledgeTokenAddress);
        let pledgeTokenBalance = await pledgeToken.methods.balanceOf(localStorage.getItem("userAccount")).call();
        if (pledgeAmount > pledgeTokenBalance) {
            // this.$toast('质押代币余额不足');
            this.$toast(this.$t('methods.t51'));
            return;
        }
        //调用质押代币向质押合约转账
        const result = await pledgeToken.methods.transfer(
            PledgeContractAddress,
            pledgeAmount
        ).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
        });

        this.pledgeLoading = false;
        if (result.transactionHash) {
            // this.$toast('质押成功');
            this.$toast(this.$t('methods.t52'));
            //刷新数据
            this.init();
            //关闭弹窗
            setTimeout(() => {
                this.pop_pledge = false;
            }, 300);
        } else {
            // this.$toast('质押失败');
            this.$toast(this.$t('methods.t53'));
        }
    } catch (error) {
        console.log(error)
        this.pledgeLoading = false;
        if (error.code == 100) {
            // this.$toast('您取消了质押操作');
            this.$toast(this.$t('methods.t54'));
        } else {
            // this.$toast('质押操作异常');
            this.$toast(this.$t('methods.t55'));
        }
    }
}
//我要赎回
export async function redeemToken() {
    //检查参数
    if (this.redeemAmount <= 0) {
        // this.$toast('赎回数量不能小于0');
        this.$toast(this.$t('methods.t56'));
        return;
    }
    //最小赎回0.01
    if (this.redeemAmount < 0.01) {
        // this.$toast('最小赎回数量为0.01');
        this.$toast(this.$t('methods.t57'));
        return;
    }
    //判断赎回数量是否超过质押数量
    if (this.redeemAmount > this.pledge.userPledge) {
        // this.$toast('赎回代币余额不足');
        this.$toast(this.$t('methods.t58'));
        return;
    }
    this.redeemLoading = true;
    try {
        //调用质押合约赎回
        const contract = new web3.eth.Contract(PledgeContractABI, PledgeContractAddress);
        const result = await contract.methods.withdraw(
            this.redeemAmount * 10 ** decimals,
        ).send({
            from: localStorage.getItem("userAccount"), // 从当前账户发送交易
        });

        this.redeemLoading = false;
        if (result.transactionHash) {
            // this.$toast('赎回成功');
            this.$toast(this.$t('methods.t59'));
            //刷新数据
            this.init();
            //关闭弹窗
            setTimeout(() => {
                this.pop_redeem = false;
            }, 300);
        } else {
            // this.$toast('赎回失败');
            this.$toast(this.$t('methods.t60'));
        }
    } catch (error) {
        console.log(error)
        this.redeemLoading = false;
        if (error.code == 100) {
            // this.$toast('您取消了赎回操作');
            this.$toast(this.$t('methods.t61'));
        } else {
            // this.$toast('赎回操作异常');
            this.$toast(this.$t('methods.t62'));
        }
    }
}
//查询往期奖池数据
export async function getHistoryPledge(stage) {
    console.log(stage)
    try {
        const contract = new web3.eth.Contract(PledgeContractABI, PledgeContractAddress);
        let pool = await contract.methods.allRecord(stage).call();
        let user = await contract.methods.userRecord(stage, localStorage.getItem('userAccount')).call();
        console.log(pool, user)
        var result = {
            myPledgeAmount: bigInt(user.pledgeAmount) / 10 ** decimals,
            myRewardAmount: bigInt(user.amount) / 10 ** decimals,
            totalPledge: bigInt(pool.pledgeAmount) / 10 ** decimals,
            headcount: bigInt(pool.headcount) - 1,
            totalReward: bigInt(pool.realAmount) / 10 ** decimals,
        }
        this.historyPledge = result;
    } catch (error) {
        console.log(error)
        return {
            myPledgeAmount: 0,
            myRewardAmount: 0,
            totalPledge: 0,
            headcount: 0,
            totalReward: 0,
        }
    }
}

