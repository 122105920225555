<template>
	<div class="header">
		<img src="../assets/logo.png" class="logo" @click="toHome">
		<div class="wallet" v-if="!userAccount" @click="connectWallet">
			<img src="../assets/wallet.png">
			<span>{{$t("home.t1")}}</span>
			<img src="../assets/more.png" style="width:0.5rem;">
		</div>
		<div class="wallet" v-else @click="copy_address">
			<img src="../assets/wallet.png">
			<span>{{ userAccount | address }}</span>
		</div>
		<div class="language" @click="setLanguage">
			<img :src="language_img" style="width:1.67rem;">
			<span>{{language=='zh-hant'?'中文':'EN'}}</span>
		</div>
	</div>
</template>

<script>
import { connectWallet } from '../utils/eth_methods.js';
//加载img
import zh from '../assets/zh-hant.png';
import en from '../assets/en.png';
export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	data() {
        return {
			userAccount: '',		//用户账户
			language: 'zh-hant',	//语言
			language_img: zh,		//语言图片
        };
    },
	async mounted() {
		//获取语言设置
		this.language = localStorage.getItem('language') || 'zh-hant';
		// 切换语言
		this.$i18n.locale = this.language
		this.language_img = this.language === 'zh-hant' ? zh : en;
		//链接钱包
		this.connectWallet();
	},
	filters: {
		address(value) {
			if (!value) return '';
			return value.slice(0, 6) + '...' + value.slice(-4);
		},
	},
	methods: {
		connectWallet,	//链接钱包,获取用户账户
		toHome(){
			//如果是首页,点击logo刷新页面
			if(this.$route.path === '/'){
				window.location.reload();
			}else{
				this.$router.replace({ path: '/' });
			}
		},
		//切换语言
		setLanguage () {
			this.language = this.language === 'zh-hant' ? 'en' : 'zh-hant';
			// 切换语言
			this.$i18n.locale = this.language
			this.language_img = this.language === 'zh-hant' ? zh : en;
			// 保存语言设置
			localStorage.setItem('language', this.language)
		},
		//点击复制地址
		copy_address(){
			this.$copyText(this.userAccount).then(() => {
				this.$toast(this.$t('home.t13'));
			});
		},
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header{
	background: #fff;
	display: flex;
	justify-content: space-between;
	position: relative;
	padding: 0rem 1.25rem;
	height: 50px;
	align-items: center;
	.logo{
		width:3.71rem;
	}
	.wallet{
		display: flex;
		align-items: center;
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		width: fit-content;
		img{
			width: 1.33rem;
		}
		span{
			margin:0 0.42rem;
			font-family: PingFang SC;
			font-size: 1.17rem;
			font-weight: bold;
			color: #000000;
		}
	}
	.language{
		width: 5.08rem;
		height: 2rem;
		background: #FFFFFF;
		border: 1px solid #000000;
		border-radius: 1rem;
		display: flex;
		align-items: center;
		padding: 0 0.17rem;
		img{
			border-radius: 50%;
		}
		span{
			margin: 0 auto;
			font-size: 1.17rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #000000;
			line-height: 2.08rem;
		}
	}
}
</style>
