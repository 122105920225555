// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	"zh-cn": {
		home: {
			t1: "连接钱包",
			t2: "我要部署",
			t3: "项目列表",
			t4: "我的铭文",
			t5: "我的订单",
			t6: "搜索",
			t7: "供应总量",
			t8: "已铸造量",
			t9: "铸造次数",
			t10: "部署时间",
			t11: "铸造",
			t12: "交易",
			t13: "钱包地址复制成功",
			t14: "暂无铸币",
			t15: "订单Hash：",
			t16: "订单ID：",
			t17: "来自：",
			t18: "去往：",
			t19: "时间：",
			t20: "出售数量：",
			t21: "出售价格：",
			t22: "撤 单",
			t23: "暂无数据",
			t24: "撤单中...",
			t25: "挂单中",
			t26: "已撤单",
			t27: "已成交",
			t28: "复制成功",
			t29: "3RC20平台是3TChat推出的一款去中心化铸文市场，用户可以在平台中进行铸文的部署、铸造、转移。也可以进行市场挂单，在市场中进行自由交易。",
			t30: "部署",
			t31: "质押",
		},
		homeOption: {
			t1: "出售订单",
			t2: "购买订单",
			t3: "不限",
			t4: "挂单中",
			t5: "已撤单",
			t6: "已成交"
		},
		deploy: {
			t1: "返回",
			t2: "3RC20部署",
			t3: "铸文币名称",
			t4: "总发行量",
			t5: "铸造限制",
			t6: "钱包地址",
			t7: "无效的代币名称，仅限输入4个字符，允许包含数字、字母、$符号(限一个)、且不能为纯数字",
			t8: "无效的总发行量，仅限输入大于0的正整数",
			t9: "无效的铸造限制，仅限输入大于0的正整数且不超过总发行量",
			t10: "（最大不超过一万亿）",
			t11: "（单次最大可铸造数量）",
			t12: "Gas费",
			t13: "授信额度",
			t14: "立即授信3T代币",
			t15: "我已阅读并同意以下内容",
			t16: "我理解3RC20协议，超过铸造次数将无效，同一块内的多个铸造交易将无效，Nonce重复将无效。",
			t17: "据我所知，由于区块更新速度的原因，目前的代币铸造进度可能不准确，代币可能已经完成铸造。",
			t18: "立即部署",
			t19: "授信额度说明",
			t20: "每次部署和铸造将收取3T代币作为上链Gas费，执行操作前，需要授权3T代币给该合约，授权额度不足时，将无法操作。\r\n请放心，授权不会扣取3T代币。\r\n<red>收取的3T代币将由智能合约按照90%的比例自动销毁。</red>",
			t21: "我知道了",
			t22: "请上传铸文币头像",
			t23: "图片大小不能超过",
			t24: "4个字符，如：“ABCD”",
			t25: "上传铸文币头像"
		},
		tokens: {
			t1: "返回",
			t2: "铸文合约地址：",
			t3: "部署者：",
			t4: "供应总量：",
			t5: "已铸造量：",
			t6: "单次Mint上限：",
			t7: "小数精度：",
			t8: "部署时间：",
			t9: "完成时间：",
			t10: "总交易量：",
			t11: "持有人数量：",
			t12: "区块高度：",
			t13: "区块链Hash：",
			t14: "持有人列表",
			t15: "序号",
			t16: "地址",
			t17: "比例",
			t18: "数量",
			t19: "暂无数据",
			t20: "我要铸造"
		},
		mint: {
			t1: "返回",
			t2: "铸造",
			t3: "剩余可铸造量",
			t4: "铸造限制",
			t5: "铸造份数",
			t6: "钱包地址",
			t7: "服务费",
			t8: "授信额度",
			t9: "单次铸造数量必须小于",
			t10: "铸造总量不可超过剩余可铸造量",
			t11: "立即授信3T代币",
			t12: "立即铸造"
		},
		myToken: {
			t1: "返回",
			t2: "购买",
			t3: "挂单",
			t4: "转账",
			t5: "加载更多",
			t6: "铸造",
			t7: "转账",
			t8: "撤单-解锁",
			t9: "挂单-锁定",
			t10: "成交-出售",
			t11: "成交-购买",
			t12: "转账地址",
			t13: "转账数量",
			t14: "当前可转增数量：",
			t15: "全部发送",
			t16: "注意：资产转移后，将从你的余额中扣除",
			t17: "取消",
			t18: "确认",
			t19: "挂单数量",
			t20: "挂单价格",
			t21: "Gas费按照0.1U收取",
			t22: "可用余额：",
			t23: "无效的接收地址",
			t24: "请输入转账数量",
			t25: "余额不足",
			t26: "请输入挂单数量",
			t27: "请输入挂单价格",
			t28: "质押",
			t29: "赎回",
			t30: "分红",
			t31: "订单",
			t32: "转账",
			t33: "铸造",
			t34: "单价",
			t35: "预计收益",
			t36: "确认挂单？",
			t37: "单价：",
			t38: "数量：",
			t39: "预期收益：",
			t40: "手续费：",
			t41: "确认",
			t42: "取消",
		},
		methods: {
			t1: "未检测到钱包，请先安装钱包",
			t2: "账户可能已注销",
			t3: "您拒绝了额度授信",
			t4: "额度授信中...",
			t5: "请检查输入参数是否正确",
			t6: "请先阅读并同意以下内容",
			t7: "名称已被使用",
			t8: "代币部署中...",
			t9: "代币部署成功",
			t10: "代币部署失败",
			t11: "您拒绝了部署交易",
			t12: "请输入正确的代币名称",
			t13: "每份铸造不能小于0",
			t14: "铸造份数不能小于0",
			t15: "每份铸造不能超过单次铸造限制",
			t16: "剩余可铸造数量不足",
			t17: "请先阅读并同意以下内容",
			t18: "代币铸造中...",
			t19: "授权额度不足，调用钱包进行额度授权",
			t20: "铸造成功",
			t21: "铸造失败",
			t22: "您拒绝了铸造交易",
			t23: "参数错误",
			t24: "不能转账给自己",
			t25: "转账成功",
			t26: "转账失败",
			t27: "您拒绝了转账交易",
			t28: "出售数量不能大于持有数量",
			t29: "出售数量不能小于0",
			t30: "出售价格不能小于0",
			t31: "挂单中...",
			t32: "挂单成功",
			t33: "挂单失败",
			t34: "您拒绝了挂单交易",
			t35: "撤单成功",
			t36: "撤单失败",
			t37: "您拒绝了撤单交易",
			t38: "切换网络失败，请刷新页面重试",
			t39: "不能购买自己的挂单",
			t40: "3T币余额不足",
			t41: "购买中...",
			t42: "购买成功",
			t43: "购买失败",
			t44: "您拒绝了购买交易",
			t45: "交易失败：此订单已被购买或被撤单",
			t46: "铸造失败：超过限制或重量不足",
			t47: "未开放",
			t48: '挂单最大限制为：',
			t49: '质押数量不能小于0',
			t50: '最小质押数量为0.01',
			t51: '质押代币余额不足',
			t52: '质押成功',
			t53: '质押失败',
			t54: '您拒绝了质押交易',
			t55: '质押操作异常',
			t56: '赎回数量不能小于0',
			t57: '最小赎回数量为0.01',
			t58: '赎回代币余额不足',
			t59: '赎回成功',
			t60: '赎回失败',
			t61: '您拒绝了赎回交易',
			t62: '赎回操作异常',
			t63: '终止交易',
			t64: '无法完全转移，请留下少量余额',
			t65: '关闭',
			t66: '最多两位小数',
		},
		api: {
			t1: "图片上传中...",
			t2: "图片上传失败",
			t3: "时",
			t4: "分",
			t5: "秒"
		},
		tradeRecord: {
			t1: "返回",
			t2: "交易动态",
			t3: "区块：",
			t4: "数量：",
			t5: "单价：",
			t6: "时间：",
			t7: "来自：",
			t8: "去往：",
			t9: "交易Hash：",
			t10: "加载更多",
			t11: "挂单",
			t12: "撤单",
			t13: "成交",
			t14: "复制成功"
		},
		market: {
			// t1: "价格：从高到低",
			// t2: "价格：从低到高",
			t1: "价格优先",
			t2: "数量优先",
			t3: "交易动态",
			t4: "购买",
			t5: "暂无挂单",
			t6: "确认购买",
			t7: "Gas费按照0.1U收取",
			t8: "收取",
			t9: "实际支付",
			t10: "可用余额：",
			t11: "取消",
			t12: "确认",
			t13: '确认撤单',
			t14: '成交金额',
			t15: '最新成交价',
			t16: '挂单总量',
			t17: '总成交次数',
			t18: '总成交量',
		},
		white:{
			t1: '无法提供铸造服务',
			t2: '您不在白名单或铸造次数已用完，无法铸造',
			t3: '确定',
			t4: '白名单用户',
			t5: '您的铸造机会剩余：',
			t6: '确定',
			t7: '铸造份数超限',
			t8: '份',
			t9: '知道了',
		},
		pledge:{
			t1: '质押分红',
			t2: '3RC20质押现已开放,质押$3RC代币可赚取3T Token。',
			t3: '每周二0时-20时开启质押，每周日20时开启分红',
			t4: '质押时间：',
			t5: '奖金池',
			t6: '已销毁总量',
			t7: '质押人数',
			t8: '质押总数',
			t9: '我的质押',
			t10: '我要质押',
			t11: '赎回质押',
			t12: '当前未开放质押与赎回',
			t13: '往期数据',
			t14: '我的质押',
			t15: '获得分红',
			t16: '总质押',
			t17: '质押人数',
			t18: '当期分红',
			t19: '暂无往期数据',
			t20: '往期数据',
			t21: '确定',
			t22: '取消',
			t23: '质押代币',
			t24: '质押数量',
			t25: '当前可质押数量：',
			t26: '全部质押',
			t27: '每个质押开放期可赎回质押币',
			t28: '取消',
			t29: '确认',
			t30: '质押赎回',
			t31: '赎回数量',
			t32: '当前可赎回数量：',
			t33: '全部赎回',
			t34: '赎回将收取5‰质押币作为手续费',
			t35: '取消',
			t36: '确认',
			t37: '奖池说明',
			t38: '奖金池构成说明：<p>1.用户每次部署和铸造收取的3T手续费，由合约自动销毁90%后,剩余部分进入奖池</p><p>2.市场交易（包含：挂单、撤单 、购买）的手续费全额进入奖金池。 </p><p>奖金池每周日晚8点进行分红，分红比例依当期用户质押比例进行派发且分红需大于0.0001。</p> ',
			t39: '质押代币余额不足，请前往铸造或市场购买',
			t40: '请输入质押数量',
			t41: '质押代币余额不足',
			t42: '暂无可赎回质押',
			t43: '请输入赎回数量',
			t44: '赎回数量不能大于质押数量',
			t45: "第{number}期",
			t46: "知道了",
		}
	},
	"zh-hant": {
		home: {
			t1: '連接錢包',
			t2: '我要部署',
			t3: '項目列表',
			t4: '我的銘文',
			t5: '我的訂單',
			t6: '搜索',
			t7: '供應總量',
			t8: '已鑄造量',
			t9: '鑄造次數',
			t10: '部署時間',
			t11: '鑄造',
			t12: '交易',
			t13: '钱包地址复制成功',
			t14: '暫無銘文幣',
			t15: '訂單Hash：',
			t16: '訂單ID：',
			t17: '來自：',
			t18: '去往：',
			t19: '時間：',
			t20: '出售數量：',
			t21: '出售價格：',
			t22: '撤 單',
			t23: '暫無數據',
			t24: '撤單中...',
			t25: '掛單中',
			t26: '已撤單',
			t27: '已成交',
			t28: '複製成功',
			t29: '3RC20平台是3TChat推出的一款去中心化銘文市場，用戶可以在平台中進行銘文的部署、鑄造、轉移。也可以進行市場掛單，在市場中進行自由交易。',
			t30: '部署',
			t31: '質押',
		},
		homeOption: {
			t1: '出售訂單',
			t2: '購買訂單',
			t3: '不限',
			t4: '掛單中',
			t5: '已撤單',
			t6: '已成交',
		},
		deploy: {
			t1: '返回',
			t2: '3RC20部署',
			t3: '銘文幣名稱',
			t4: '總發行量',
			t5: '鑄造限制',
			t6: '錢包地址',
			t7: '無效的代幣名稱，僅限輸入4個字符，只允許包含數字、字母和$符號(限一個)、且不能為純數字',
			t8: '無效的總發行量，僅限輸入大於0的正整數',
			t9: '無效的鑄造限制，僅限輸入大於0的正整數且不超過總發行量',
			t10: '（最大不超過一萬億）',
			t11: '（單次最大可鑄造數量）',
			t12: 'Gas費',
			t13: '授信額度',
			t14: '立即授信3T代幣',
			t15: '我已閱讀並同意以下內容',
			t16: '我理解3RC20協議，超過鑄造次數將無效，同一塊內的多個鑄造交易將無效，Nonce重複將無效。',
			t17: '據我所知，由於區塊更新速度的原因，目前的代幣鑄造進度可能不準確，代幣可能已經完成鑄造。',
			t18: '立即部署',
			t19: '授信額度說明',
			t20: '每次部署和鑄造將收取3T代幣作為上鏈Gas費，執行操作前，需要授權3T代幣給該合約，授權額度不足時，將無法操作。\r\n請放心，授權不會扣取3T代幣。\r\n<red>收取的3T代幣將由智能合約按照90%的比例自動銷毀。</red>',
			t21: '我知道了',
			t22: '請上傳銘文幣頭像',
			t23: '圖片大小不能超過',
			t24: '4個字符，如：“ABCD”',
			t25: '上傳銘文幣頭像',
		},
		tokens: {
			t1: '返回',
			t2: '銘文合約地址：',
			t3: '部署者：',
			t4: '供應總量：',
			t5: '已鑄造量：',
			t6: '單次Mint上限：',
			t7: '小數精度：',
			t8: '部署時間：',
			t9: '完成時間：',
			t10: '总交易量：',
			t11: '持有人數量：',
			t12: '區塊高度：',
			t13: '區塊鏈Hash：',
			t14: '持有人列表',
			t15: '序號',
			t16: '地址',
			t17: '比例',
			t18: '數量',
			t19: '暫無數據',
			t20: '我要鑄造',
		},
		mint: {
			t1: '返回',
			t2: '鑄造',
			t3: '剩餘可鑄造量',
			t4: '鑄造限制',
			t5: '鑄造份数',
			t6: '錢包地址',
			t7: '服務費',
			t8: '授信額度',
			t9: '单次鑄造數量必須小於',
			t10: '鑄造總量不可超過剩餘可鑄造量',
			t11: '立即授信3T代币',
			t12: '立即鑄造',
		},
		myToken: {
			t1: '返回',
			t2: '購買',
			t3: '掛單',
			t4: '轉帳',
			t5: '加載更多',
			t6: '鑄造',
			t7: '轉賬',
			t8: '撤單-解鎖',
			t9: '掛單-鎖定',
			t10: '成交-出售',
			t11: '成交-購買',
			t12: '轉賬地址',
			t13: '轉賬數量',
			t14: '當前可轉增數量：',
			t15: '全部發送',
			t16: '注意：資產轉移後，將從你的余額中扣除',
			t17: '取消',
			t18: '確認',
			t19: '掛單數量',
			t20: '掛單價格',
			t21: 'Gas費按照0.1U收取',
			t22: '可用余額：',
			t23: '無效的接收地址',
			t24: '請輸入轉賬數量',
			t25: '余額不足',
			t26: '請輸入掛單數量',
			t27: '請輸入掛單價格',
			t28: '質押',
			t29: '贖回',
			t30: '分紅',
			t31: '訂單',
			t32: '轉賬',
			t33: '鑄造',
			t34: '單價',
			t35: '預計收益',
			t36: '確認掛單？',
			t37: '單價：',
			t38: '數量：',
			t39: '預期收益：',
			t40: '手續費：',
			t41: '確認',
			t42: '取消',
		},
		methods: {
			t1: '未檢測到錢包，請先安裝錢包',
			t2: '賬戶可能已註銷',
			t3: '額度授信中...',
			t4: '您拒絕了額度授信',
			t5: '請檢查輸入參數是否正確',
			t6: '請先閱讀並同意以下內容',
			t7: '名稱已被使用',
			t8: '代币部署中...',
			t9: '代币部署成功',
			t10: '代币部署失败',
			t11: '您拒絕了部署交易',
			t12: '請輸入正確的代币名稱',
			t13: '每份鑄造不能小於0',
			t14: '鑄造份数不能小於0',
			t15: '每份鑄造不能超過單次鑄造限制',
			t16: '剩余可鑄造數量不足',
			t17: '請先閱讀並同意以下內容',
			t18: '代币鑄造中...',
			t19: '授權額度不足，調用錢包進行額度授權',
			t20: '鑄造成功',
			t21: '鑄造失敗',
			t22: '您拒絕了鑄造交易',
			t23: '參數錯誤',
			t24: '不能轉賬給自己',
			t25: '轉賬成功',
			t26: '轉賬失敗',
			t27: '您拒絕了轉賬交易',
			t28: '出售數量不能大於持有數量',
			t29: '出售數量不能小於0',
			t30: '出售價格不能小於0',
			t31: '掛單中...',
			t32: '掛單成功',
			t33: '掛單失敗',
			t34: '您拒絕了掛單交易',
			t35: '撤單成功',
			t36: '撤單失敗',
			t37: '您拒絕了撤單交易',
			t38: '切換網絡失敗，請刷新頁面重試',
			t39: '不能購買自己的掛單',
			t40: '3T幣余額不足',
			t41: '購買中...',
			t42: '購買成功',
			t43: '購買失敗',
			t44: '您拒絕了購買交易',
			t45: '交易失敗：此訂單已被購買或被撤單',
			t46: '鑄造失敗：超過限制或重量不足',
			t47: '未開放',
			t48: '掛單最大限制為：',
			t49: '質押數量不能小於0',
			t50: '最小質押數量為0.01',
			t51: '質押代幣餘額不足',
			t52: '質押成功',
			t53: '質押失敗',
			t54: '您拒絕了質押交易',
			t55: '質押操作異常',
			t56: '贖回數量不能小於0',
			t57: '最小贖回數量為0.01',
			t58: '贖回代幣餘額不足',
			t59: '贖回成功',
			t60: '贖回失敗',
			t61: '您拒絕了贖回交易',
			t62: '贖回操作異常',	
			t63: '終止交易',
			t64: '無法完全轉移，請留下少量余額',
			t65: '關閉',		
			t66: '最多兩位小數',
		},
		api:{
			t1: '圖片上傳中...',
			t2: '圖片上傳失敗',
			t3: '時',
			t4: '分',
			t5: '秒',
		},
		tradeRecord:{
			t1: '返回',
			t2: '交易動態',
			t3: '區塊：',
			t4: '數量：',
			t5: '單價：',
			t6: '時間：',
			t7: '來自：',
			t8: '去往：',
			t9: '交易Hash：',
			t10: '加載更多',
			t11: '掛單',
			t12: '撤單',
			t13: '成交',
			t14: '複製成功',
		},
		market:{
			// t1: '價格：從高到低',
			// t2: '價格：從低到高',
			t1: '價格優先',
			t2: '數量優先',
			t3: '交易動態',
			t4: '購買',
			t5: '暫無掛單',
			t6: '確認購買',
			t7: 'Gas費按照0.1U收取',
			t8: '收取',
			t9: '實際支付',
			t10: '可用余額：',
			t11: '取消',
			t12: '確認',
			t13: '確認撤單',
			t14: '成交金額',
			t15: '最新成交價',
			t16: '掛單總量',
			t17: '總成交次數',
			t18: '總成交量',
		},
		white:{
			t1: '無法提供鑄造服務',
			t2: '您不在白名單或鑄造次數已用完，無法鑄造',
			t3: '確定',
			t4: '白名單用戶',
			t5: '您的鑄造機會剩余：',
			t6: '確定',
			t7: '鑄造份数超限',
			t8: '份',
			t9: '知道了'
		},
		pledge:{
			t1: '質押分紅',
			t2: '3RC20質押現已開放,質押$3RC代幣可賺取3T Token。 ',
			t3: '每周二0時-20時開啟質押，每周日20時開啟分紅',
			t4: '質押時間：',
			t5: '獎金池',
			t6: '已銷毀總量',
			t7: '質押人數',
			t8: '質押總數',
			t9: '我的質押',
			t10: '我要質押',
			t11: '贖回質押',
			t12: '目前未開放質押與贖回',
			t13: '往期資料',
			t14: '我的質押',
			t15: '獲得分紅',
			t16: '總質押',
			t17: '質押人數',
			t18: '當期分紅',
			t19: '暫無往期資料',
			t20: '往期資料',
			t21: '確定',
			t22: '取消',
			t23: '質押代幣',
			t24: '質押數',
			t25: '目前可質押數：',
			t26: '全部質押',
			t27: '每個質押開放期限可贖回質押幣',
			t28: '取消',
			t29: '確認',
			t30: '質押贖回',
			t31: '贖回數量',
			t32: '目前可贖回數：',
			t33: '全部贖回',
			t34: '贖回將收取5‰質押幣作為手續費',
			t35: '取消',
			t36: '確認',
			t37: '獎池說明',
			t38: '獎金池構成說明：<p>1.用戶每次部署和鑄造收取的3T手續費，由合約自動銷毀90%後,剩餘部分進入獎池</p><p>2.市場交易（包含：掛單、撤單 、購買）的手續費全額進入獎金池。 </p><p>獎金池每週日晚8點進行分紅，分紅比例依當期用戶質押比例進行派發且分紅需大於0.0001。</p> ',
			t39: '質押代幣餘額不足，請前往鑄造或市場購買',
			t40: '請輸入質押數量',
			t41: '質押代幣餘額不足',
			t42: '暫無可贖回質押',
			t43: '請輸入贖回數量',
			t44: '贖回數量不能大於質押數量',
			t45: "第{number}期",
			t46: "知道了",
		}
	},
	"en": {
		home: {
			t1: 'Connect Wallet',
			t2: 'Deploy',
			t3: 'Projects',
			t4: 'Tokens',
			t5: 'Orders',
			t6: 'Search',
			t7: 'Total Supply',
			t8: 'Minted',
			t9: 'Mint Count',
			t10: 'Deployed Time',
			t11: 'Mint',
			t12: 'Trade',
			t13: 'Wallet address copied successfully',
			t14: 'No tokens',
			t15: 'Order Hash:',
			t16: 'Order ID:',
			t17: 'From:',
			t18: 'To:',
			t19: 'Time:',
			t20: 'Amount:',
			t21: 'Price:',
			t22: 'Cancel',
			t23: 'No data',
			t24: 'Canceling...',
			t25: 'Pending',
			t26: 'Canceled',
			t27: 'Completed',
			t28: 'Copied successfully',
			t29: '3RC20 is a decentralized token market launched by 3TChat. Users can deploy, mint, and transfer tokens in the market. You can also place orders in the market and trade freely.',
			t30: 'Deploy',
			t31: 'Pledge',
		},
		homeOption: {
			t1: 'Sell',
			t2: 'Buy',
			t3: 'All',
			t4: 'Pending',
			t5: 'Canceled',
			t6: 'Completed',
		},
		deploy: {
			t1: 'Back',
			t2: '3RC20 Deploy',
			t3: 'Token Name',
			t4: 'Total Supply',
			t5: 'Mint Limit',
			t6: 'Wallet Address',
			t7: 'Invalid token name, only 4 characters are allowed, only numbers, letters and $ symbols (limited to one) are allowed, and cannot be pure numbers',
			t8: 'Invalid total supply, only positive integers greater than 0 are allowed',
			t9: 'Invalid mint limit, only positive integers greater than 0 and less than or equal to the total supply are allowed',
			t10:'(Maximum not exceeding one trillion)',
			t11:'(Maximum minted quantity per transaction)',
			t12:'Service Fee',
			t13:'allowance',
			t14:'Allowance 3T',
			t15:'I have read and agree to the following',
			t16:'I understand the 3RC20 protocol, exceeding the minting limit will be invalid, and multiple minting transactions in the same block will be invalid, and duplicate Nonce will be invalid.',
			t17:'To the best of my knowledge, due to the speed of block updates, the current token minting progress may be inaccurate, and the token may have been minted.',
			t18:'Deploy Now',
			t19:'Credit Limit Description',
			t20:'Each deployment and casting will charge a 3T token as an on chain gas fee. Before executing the operation, it is necessary to authorize the 3T token to the contract. If the authorization amount is insufficient, the operation will not be possible. \r\nPlease rest assured that authorization will not deduct 3T tokens. \r\n<red>The 3T tokens collected will be automatically destroyed by the smart contract in a 90% proportion</red>',
			t21:'I know',
			t22:'Please upload the token avatar',
			t23:'The picture size cannot exceed',
			t24:'4 letters, such as: "ABCD"',
			t25:'Upload Token Avatar',
		},
		tokens: {
			t1: 'Back',
			t2: 'Mint Contract Address:',
			t3: 'Deploy By:',
			t4: 'Total Supply:',
			t5: 'Minted:',
			t6: 'Limit per mint:',
			t7: 'Decimal:',
			t8: 'Deployed Time:',
			t9: 'Finished Time:',
			t10: 'Total Transactions:',
			t11: 'Holders:',
			t12: 'Block Number:',
			t13: 'Block Hash:',
			t14: 'Holders',
			t15: 'No.',
			t16: 'Address',
			t17: 'Ratio',
			t18: 'Amount',
			t19: 'No data',
			t20: 'Mint',
		},
		mint: {
			t1: 'Back',
			t2: 'Mint',
			t3: 'Remaining Mintable',
			t4: 'Mint Limit',
			t5: 'Mint Count',
			t6: 'Wallet Address',
			t7: 'Service Fee',
			t8: 'allowance',
			t9: 'The quantity of a single mint must be less than ',
			t10: 'The total amount of minting cannot exceed the remaining amount of minting',
			t11: 'Allowance 3T',
			t12: 'Mint Now',
		},
		myToken:{
			t1: 'Back',
			t2: 'Buy',
			t3: 'Listing',
			t4: 'Transfer',
			t5: 'Load More',
			t6: 'Mint',
			t7: 'Transfer',
			t8: 'Order-Unlock',
			t9: 'Order-Lock',
			t10: 'Deal-Sell',
			t11: 'Deal-Buy',
			t12: 'Transfer Address',
			t13: 'Transfer Amount',
			t14: 'Available Transferable Quantity:',
			t15: 'Send All',
			t16: 'Note: After asset transfer, it will be deducted from your balance.',
			t17: 'Cancel',
			t18: 'Confirm',
			t19: 'Order Quantity',
			t20: 'Order Price',
			t21: 'Gas fee is charged at 0.1U',
			t22: 'Available Balance:',
			t23: 'Invalid receiving address',
			t24: 'Please enter the transfer amount',
			t25: 'Insufficient balance',
			t26: 'Please enter the order quantity',
			t27: 'Please enter the order price',
			t28: 'Pledge',
			t29: 'Redeem',
			t30: 'Dividend',
			t31: 'Order',
			t32: 'Transfer',
			t33: 'Mint',
			t34: 'Price',
			t35: 'Expected Earnings',
			t36: 'Confirm Listing?',
			t37: 'Price:',
			t38: 'Quantity:',
			t39: 'Expected Earnings:',
			t40: 'Service Fee:',
			t41: 'Confirm',
			t42: 'Cancel',
		},
		methods:{
			t1: 'No wallet detected, please install wallet first',
			t2: 'Account may have been logged out',
			t3: 'You rejected the credit limit',
			t4: 'Credit limit in progress...',
			t5: 'Please check if the input parameters are correct',
			t6: 'Please read and agree to the following first',
			t7: 'Name has been used',
			t8: 'Token deployment in progress...',
			t9: 'Token deployment succeeded',
			t10: 'Token deployment failed',
			t11: 'You rejected the deployment transaction',
			t12: 'Please enter the correct token name',
			t13: 'Each mint cannot be less than 0',
			t14: 'The number of minting copies cannot be less than 0',
			t15: 'Each mint cannot exceed the single mint limit',
			t16: 'Insufficient remaining mintable quantity',
			t17: 'Please read and agree to the following first',
			t18: 'Token minting in progress...',
			t19: 'Insufficient authorized amount, call the wallet to authorize the amount',
			t20: 'Mint succeeded',
			t21: 'Mint failed',
			t22: 'You rejected the mint transaction',
			t23: 'Parameter error',
			t24: 'Cannot transfer to yourself',
			t25: 'Transfer succeeded',
			t26: 'Transfer failed',
			t27: 'You rejected the transfer transaction',
			t28: 'The number of sales cannot be greater than the number of holdings',
			t29: 'The number of sales cannot be less than 0',
			t30: 'The price cannot be less than 0',
			t31: 'Listing in progress...',
			t32: 'Listing succeeded',
			t33: 'Listing failed',
			t34: 'You rejected the listing transaction',
			t35: 'Cancel succeeded',
			t36: 'Cancel failed',
			t37: 'You rejected the cancel transaction',
			t38: "Instructions on the composition of the bonus pool: <p>1. After 90% of the 3T handling fee charged by the user for each deployment and casting is automatically destroyed by the contract, the remaining part enters the bonus pool</p><p>2. Market transactions (including: pending orders, The handling fee for order cancellation and purchase will be fully entered into the bonus pool. </p><p>The bonus pool will distribute dividends every Sunday at 8 p.m. The dividend ratio will be distributed according to the user's pledge ratio in the current period and the dividend must be greater than 0.0001. </p>",
			t39: 'Cannot buy your own listing',
			t40: 'Insufficient 3T balance',
			t41: 'Buying...',
			t42: 'Buy succeeded',
			t43: 'Buy failed',
			t44: 'You rejected the buy transaction',
			t45: 'Transaction failed: This order has been purchased or canceled',
			t46: 'Minting failed: Exceeded the limit or insufficient weight',
			t47: 'Not open',
			t48: 'The maximum limit for pending orders is:',
			t49: 'The number of pledges cannot be less than 0',
			t50: 'The minimum pledge quantity is 0.01',
			t51: 'Insufficient balance of pledged tokens',
			t52: 'Pledge succeeded',
			t53: 'Pledge failed',
			t54: 'You rejected the pledge transaction',
			t55: 'Pledge operation abnormal',
			t56: 'The number of redemptions cannot be less than 0',
			t57: 'The minimum redemption quantity is 0.01',
			t58: 'Insufficient balance of pledged tokens',
			t59: 'Redemption succeeded',
			t60: 'Redemption failed',
			t61: 'You rejected the redemption transaction',
			t62: 'Redemption operation abnormal',
			t63: 'Terminate transaction',
			t64: 'Unable to transfer completely, please leave a small amount of balance',
			t65: 'Close',
			t66: 'Up to two decimal places',
		},
		api:{
			t1: 'Picture uploading...',
			t2: 'Upload failed',
			t3: 'hour',
			t4: 'minute',
			t5: 'second',
		},
		tradeRecord:{
			t1: 'Back',
			t2: 'Trade Record',
			t3: 'Block: ',
			t4: 'Amount: ',
			t5: 'Price: ',
			t6: 'Time: ',
			t7: 'From: ',
			t8: 'To: ',
			t9: 'Trade Hash: ',
			t10: 'Load More',
			t11: 'Pending',
			t12: 'Cancel',
			t13: 'Deal',
			t14: 'Copied successfully',
		},
		market:{
			// t1: 'Price: High to Low',
			// t2: 'Price: Low to High',
			t1: 'Price Priority',
			t2: 'Quantity Priority',
			t3: 'Trade Record',
			t4: 'Buy',
			t5: 'No orders',
			t6: 'Confirm',
			t7: 'Gas fee is charged at 0.1U',
			t8: 'Collect',
			t9: 'Actual payment',
			t10: 'Available balance:',
			t11: 'Cancel',
			t12: 'Confirm',
			t13: 'Confirm cancel',
			t14: 'Deal amount',
			t15: 'Latest price',
			t16: 'Pending orders',
			t17: 'Total times',
			t18: 'Total volume',
		},
		white:{
			t1: 'Unable to provide minting service',
			t2: 'You are not on the whitelist or the number of minting times has been used up, unable to mint',
			t3: 'Confirm',
			t4: 'Whitelist user',
			t5: 'Your minting opportunity is left: ',
			t6: 'Confirm',
			t7: 'Minting copies exceeded',
			t8: 'copies',
			t9: 'Got it',
		},
		pledge:{
			t1: 'Pledge Bonus',
			t2: '3RC20 pledge is now open, pledge $3RC token can earn 3T Token.',
			t3: 'Pledge time: Every Tuesday 0:00-20:00, bonus time: Every Sunday 20:00',
			t4: 'Pledge time:',
			t5: 'Bonus Pool',
			t6: 'Total Burned',
			t7: 'Pledge Count',
			t8: 'Total Pledge',
			t9: 'My Pledge',
			t10: 'Pledge',
			t11: 'Redeem',
			t12: 'Pledge and redemption are not currently open',
			t13: 'Past data',
			t14: 'My Pledge',
			t15: 'Bonus',
			t16: 'Total Pledge',
			t17: 'Pledge Count',
			t18: 'Bonus',
			t19: 'No past data',
			t20: 'Past data',
			t21: 'Confirm',
			t22: 'Cancel',
			t23: 'Pledge Token',
			t24: 'Pledge Count',
			t25: 'Available pledge count:',
			t26: 'All pledge',
			t27: 'Each pledge open period can redeem pledge coins',
			t28: 'Cancel',
			t29: 'Confirm',
			t30: 'Pledge redemption',
			t31: 'Redeem Count',
			t32: 'Available redemption count:',
			t33: 'All redemption',
			t34: 'Redemption will charge 5‰ pledge coins as handling fee',
			t35: 'Cancel',
			t36: 'Confirm',
			t37: 'Bonus Pool Description',
			t38: 'Bonus Pool Composition Description: \r\n1. The 3T handling fee charged by users for each deployment and minting is automatically destroyed by the contract in a 90% ratio, and the remaining part enters the bonus pool \r\n2. The handling fee for market transactions (including: listing, canceling, and buying) enters the bonus pool in full. \r\n<br />The bonus pool is distributed every Sunday evening at 8 o\'clock, and the bonus ratio is distributed according to the current user\'s pledge ratio and the bonus must be greater than 0.0001.',
			t39: 'Insufficient balance of pledged tokens, please go to the minting or market to purchase',
			t40: 'Please enter the pledge amount',
			t41: 'Insufficient balance of pledged tokens',
			t42: 'No redeemable pledge',
			t43: 'Please enter the redemption amount',
			t44: 'The redemption amount cannot be greater than the pledge amount',
			t45: "Phase {number}",
			t46: "Got it",
		}
	},
};

const i18n = new VueI18n({
	locale: 'en', // 默认语言
	fallbackLocale: 'en', // 未找到翻译时的默认语言
	messages
});

export default i18n;
