function int2hex(num, width) {
    /* 转换 */
    var hex = '0123456789abcdef'
    var s = ''
    while (num) {
        s = hex.charAt(num % 16) + s; num = Math.floor(num / 16)
    }
    if (typeof width === 'undefined' || width <= s.length) {
        return '0x' + s
    }
    var delta = width - s.length
    var padding = ''
    while (delta-- > 0) {
        padding += '0'
    }
    return '0x' + padding + s
}

export default int2hex