export function numToK(value,max=9,min=0) {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return noExponents(formatter.format(value));
}
export function handleAmount(value,max=9,min=0) {
    let amount = value / 10 ** 9;
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    });
    return noExponents(formatter.format(amount));
}
export const noExponents = (exponent) => {
    const data = String(exponent).split(/[eE]/);
    if (data.length === 1) return data[0];
    let z = '', sign = exponent < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;
    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
};

export function int2hex(num, width) {
    /* 转换 */
    var hex = '0123456789abcdef'
    var s = ''
    while (num) {
        s = hex.charAt(num % 16) + s; num = Math.floor(num / 16)
    }
    if (typeof width === 'undefined' || width <= s.length) {
        return '0x' + s
    }
    var delta = width - s.length
    var padding = ''
    while (delta-- > 0) {
        padding += '0'
    }
    return '0x' + padding + s
}
